import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function TryMyBestRunningTeeSizeChart() {
  const XXS = "XXS";
  const XS = "XS";
  const S = "S";
  const M = "M";
  const L = "L";
  const XL = "XL";

  const XXSWaist = '25"-26"';
  const XSWaist = '27"-28"';
  const SWaist = '29"-30"';
  const MWaist = '31"-32"';
  const LWaist = '33"-34"';

  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="try my best running tee size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text={XXS} />
                <ChartCell text={XS} />
                <ChartCell text={S} />
                <ChartCell text={M} />
                <ChartCell text={L} />
                <ChartCell text={XL} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Half Chest (cm)" />
                <ChartCell text="45" />
                <ChartCell text="47" />
                <ChartCell text="49" />
                <ChartCell text="51" />
                <ChartCell text="53" />
                <ChartCell text="55" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (cm)" />
                <ChartCell text="42" />
                <ChartCell text="44" />
                <ChartCell text="46" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="52" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Bottom Width (cm)" />
                <ChartCell text="45" />
                <ChartCell text="47" />
                <ChartCell text="49" />
                <ChartCell text="51" />
                <ChartCell text="53" />
                <ChartCell text="55" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Sleeves Length (cm)" />
                <ChartCell text="28" />
                <ChartCell text="29.5" />
                <ChartCell text="31" />
                <ChartCell text="32.5" />
                <ChartCell text="34" />
                <ChartCell text="35" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="62" />
                <ChartCell text="64" />
                <ChartCell text="66" />
                <ChartCell text="68" />
                <ChartCell text="70" />
                <ChartCell text="72" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={8} display={{ xs: "none", sm: "block" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="try my best running tee size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text={XXS} />
                <ChartCell text={XS} />
                <ChartCell text={S} />
                <ChartCell text={M} />
                <ChartCell text={L} />
                <ChartCell text={XL} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Half Chest (cm)" />
                <ChartCell text="45" />
                <ChartCell text="47" />
                <ChartCell text="49" />
                <ChartCell text="51" />
                <ChartCell text="53" />
                <ChartCell text="55" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (cm)" />
                <ChartCell text="42" />
                <ChartCell text="44" />
                <ChartCell text="46" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="52" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Bottom Width (cm)" />
                <ChartCell text="45" />
                <ChartCell text="47" />
                <ChartCell text="49" />
                <ChartCell text="51" />
                <ChartCell text="53" />
                <ChartCell text="55" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Sleeves Length (cm)" />
                <ChartCell text="28" />
                <ChartCell text="29.5" />
                <ChartCell text="31" />
                <ChartCell text="32.5" />
                <ChartCell text="34" />
                <ChartCell text="35" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="62" />
                <ChartCell text="64" />
                <ChartCell text="66" />
                <ChartCell text="68" />
                <ChartCell text="70" />
                <ChartCell text="72" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default TryMyBestRunningTeeSizeChart;

import { Box, Typography } from "@material-ui/core";

function TryMyBestHongKong1841TeeDescription() {
  return (
    <>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          “ 1841 “
          是香港開埠的年份，這百多年來，由小漁村銳變成為國際都市，成就非比尋常，店長認為「Hong
          Kong」是一個品牌，它的獨特文化，鮮明個性，舉世無雙，遨遊四海時，大叔喜歡被人知道是來自香港，在香港出生的大叔，亦感到很自豪是100%
          “ Made in Hong Kong “。
        </Typography>
      </Box>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          《 Hong Kong 1841 》選用 65% Cotton，35% Polyester
          的布料，穿着時有棉質 T-shirt 的舒適，亦有合成纖維，”快乾”和”不皺免熨”
          的特性。
        </Typography>
      </Box>
    </>
  );
}

export default TryMyBestHongKong1841TeeDescription;

import React from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { siteConfigs } from "../data/data";
import logo from "../images/logo.png";

function MobileSplash() {
  const history = useHistory();

  return (
    <div
      style={{
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100vw",
        height: "100vh",
      }}
    >
      <motion.img
        onClick={() => history.push("/category-selection")}
        src={siteConfigs.mainImageUrl}
        style={{
          height: "100%",
          objectFit: "cover",
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0, transition: { duration: 5 } }}
      />
      {/* logo */}
      <motion.img
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 5 } }}
        style={{
          zIndex: 3,
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "40vw",
          transform: "translate(-50%, -60%)",
        }}
        src={logo}
      />
    </div>
  );
}

export default MobileSplash;

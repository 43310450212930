import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function CapSizeChart() {
  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="cap size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="Free" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Head Size (cm)" />
                <ChartCell text="48-57" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={14} display={{ xs: "none", sm: "block" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="cap size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="Free" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Head Size (cm)" />
                <ChartCell text="48-57" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default CapSizeChart;

import { useQuery } from "@apollo/client";
import React from "react";
import { useParams } from "react-router-dom";
import CategorySelector from "../components/CategorySelector";
import Navbar from "../components/Navbar";
import ProductImageBox from "../components/ProductImageBox";
import ProductInformationBox from "../components/ProductInformationBox";
import { StyledFullPageSection } from "../styles/styles";
import { Box } from "@material-ui/core";
import { GetProductGql } from "../api/graphql";
import Loading from "../components/Loading";

const show5images = (title) => {
  if (title.startsWith("Try My Best")) return true;
  if (title.toLowerCase().includes("blackhill running shorts")) return true;
  if (title.toLowerCase().includes("almighty jacket")) return true;
  return false;
};

function ProductByScroll() {
  const { productId } = useParams();
  const { loading, error, data } = useQuery(GetProductGql, {
    variables: { productId: `gid://shopify/Product/${productId}` },
  });

  const getLast3ImagesArray = (imagesArray) => {
    if (!Array.isArray(imagesArray)) {
      console.error("get last 3 images input must be an array");
      return;
    }

    if (imagesArray.length < 4) {
      console.error("images array should have at least 4 images");
      return;
    }

    const last = imagesArray[imagesArray.length - 1];
    const secondLast = imagesArray[imagesArray.length - 2];
    const thirdLast = imagesArray[imagesArray.length - 3];

    const last3ImagesArray = [thirdLast, secondLast, last];

    return last3ImagesArray;
  };

  const getLast5ImagesArray = (imagesArray) => {
    if (!Array.isArray(imagesArray)) {
      console.error("get last 5 images input must be an array");
      return;
    }

    if (imagesArray.length < 6) {
      console.error("images array should have at least 6 images");
      return;
    }

    const last = imagesArray[imagesArray.length - 1];
    const secondLast = imagesArray[imagesArray.length - 2];
    const thirdLast = imagesArray[imagesArray.length - 3];
    const fourthLast = imagesArray[imagesArray.length - 4];
    const fifthLast = imagesArray[imagesArray.length - 5];

    const last5ImagesArray = [
      fifthLast,
      fourthLast,
      thirdLast,
      secondLast,
      last,
    ];

    return last5ImagesArray;
  };

  if (loading) return <Loading />;
  if (error) return <p>Error: {error.message}</p>;
 
  console.log('product', data);
  // console.log("pid", productId, "data", JSON.stringify(data.node.images.edges));
  return (
    <div>
      <Navbar />
      <Box display={{ xs: "none", sm: "block" }}>
        <CategorySelector />
      </Box>
      <Box style={{ marginTop: "8rem" }}>
        {data &&
          data.node.images.edges.map((image, i) =>
            i <=
            data.node.images.edges.length -
              (show5images(data.node.title) ? 6 : 4) ? (
              <Box>
                <Box display={{ xs: "none", sm: "block" }}>
                  <StyledFullPageSection
                    key={i}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <ProductImageBox imageUrl={image.node.src} />
                  </StyledFullPageSection>
                </Box>
                <Box
                  display={{ xs: "block", sm: "none" }}
                  style={{ marginBottom: "3rem" }}
                >
                  <ProductImageBox imageUrl={image.node.src} />
                </Box>
              </Box>
            ) : null
          )}
      </Box>
      {data && (
        <StyledFullPageSection
          key={"info"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          {/* determine show 5 dot images or 3 dot images */}
          {show5images(data?.node?.title) ? (
            <ProductInformationBox
              product={data.node}
              images={getLast5ImagesArray(data.node.images.edges)}
            />
          ) : (
            <ProductInformationBox
              product={data.node}
              images={getLast3ImagesArray(data.node.images.edges)}
            />
          )}
        </StyledFullPageSection>
      )}
    </div>
  );
}

export default ProductByScroll;

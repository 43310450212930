import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function WildpiBlackhillMeshHikingPantsSizeChart() {
  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="Wildpi x blackhill Mesh Hiking Pants">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size (Slim Fit)" />
                <ChartCell text="XS" />
                <ChartCell text="S" />
                <ChartCell text="M" />
                <ChartCell text="L" />
                <ChartCell text="XL" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="88" />
                <ChartCell text="90" />
                <ChartCell text="92" />
                <ChartCell text="93" />
                <ChartCell text="93" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (inch)" />
                <ChartCell text='26"-28"' />
                <ChartCell text='28"-30"' />
                <ChartCell text='30"-32"' />
                <ChartCell text='32"-34"' />
                <ChartCell text='34"-36"' />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Front Rise (cm)" />
                <ChartCell text="26" />
                <ChartCell text="26.5" />
                <ChartCell text="27" />
                <ChartCell text="28" />
                <ChartCell text="29" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Leg Opening - Half (cm)" />
                <ChartCell text="14.5" />
                <ChartCell text="15" />
                <ChartCell text="16.5" />
                <ChartCell text="17" />
                <ChartCell text="17.5" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={8} display={{ xs: "none", sm: "block" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="Wildpi x blackhill Mesh Hiking Pants">
            <TableHead>
            <TableRow>
                <LeftChartCell text="Size (Slim Fit)" />
                <ChartCell text="XS" />
                <ChartCell text="S" />
                <ChartCell text="M" />
                <ChartCell text="L" />
                <ChartCell text="XL" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="88" />
                <ChartCell text="90" />
                <ChartCell text="92" />
                <ChartCell text="93" />
                <ChartCell text="93" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (inch)" />
                <ChartCell text='26"-28"' />
                <ChartCell text='28"-30"' />
                <ChartCell text='30"-32"' />
                <ChartCell text='32"-34"' />
                <ChartCell text='34"-36"' />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Front Rise (cm)" />
                <ChartCell text="26" />
                <ChartCell text="26.5" />
                <ChartCell text="27" />
                <ChartCell text="28" />
                <ChartCell text="29" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Leg Opening - Half (cm)" />
                <ChartCell text="14.5" />
                <ChartCell text="15" />
                <ChartCell text="16.5" />
                <ChartCell text="17" />
                <ChartCell text="17.5" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default WildpiBlackhillMeshHikingPantsSizeChart;

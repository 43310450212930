import React from "react";
import { Box, Typography } from "@material-ui/core";

function EmptyCart() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: "85vh" }}
    >
      <Typography variant="h5" align="center">
        Your Cart is Empty
      </Typography>
    </Box>
  );
}

export default EmptyCart;

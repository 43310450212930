import React, { useState } from "react";
import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";
import { useCartItems } from "../hooks/CartHooks";
import { CheckoutCreate } from "../api/graphql";
import CheckingOut from "./CheckingOut";

function CheckoutButton() {
  const [cartItems, setCartItems] = useCartItems();

  const getSubTotal = (cartItem) => {
    const { quantity, price } = cartItem;
    const amount = parseFloat(price.amount);
    return quantity * amount;
  };

  const getTotal = (cartItems) => {
    const total = cartItems.reduce((acc, cur) => acc + getSubTotal(cur), 0);
    return total;
  };

  const convertCartItemsToLineItems = (cartItems) => {
    const lineItems = cartItems.map(({ variantId, quantity }) => ({
      variantId,
      quantity,
    }));
    return lineItems;
  };

  const [checkout, { loading, error }] = useMutation(CheckoutCreate, {
    variables: {
      input: {
        lineItems: convertCartItemsToLineItems(cartItems),
      },
    },

    onCompleted: (data) => {
      setCartItems([]);
      setOpen(false);
      console.log("checkout completed", data);
      window.location.href = data.checkoutCreate.checkout.webUrl;
    },
  });

  //   dialog
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box m={5}>
      <Button
        style={{ width: "60vw", maxWidth: "300px", height: "4rem" }}
        variant="contained"
        color="primary"
        disabled={getTotal(cartItems) === 0 ? true : false}
      >
        <Typography
          variant="subtitle1"
          align="center"
          onClick={() => {
            setOpen(true);
            checkout();
          }}
        >
          <strong>Checkout</strong>
        </Typography>
      </Button>
      <Dialog fullScreen onClose={handleClose} open={open}>
        <CheckingOut />
      </Dialog>
    </Box>
  );
}

export default CheckoutButton;

export const sections = [
  {
    id: 0,
    name: "All items",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fall-items-cover.jpeg?alt=media&token=b1dfad0d-bd07-48fa-a2e7-a13c929dfc77",
    link: "/products",
  },
  {
    id: 1,
    name: "T-Shirts/Shirts",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Ft-shirt-cover.jpeg?alt=media&token=f4af164b-b113-4624-aa59-35770b16fd6b",
    link: "/products/t-shirts-shirts",
  },
  {
    id: 2,
    name: "Jackets",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fjacket-cover.jpg?alt=media&token=733e6a1f-dca7-4be6-b32d-17644e76e622",
    link: "/products/jackets",
  },
  {
    id: 3,
    name: "Shorts/Pants",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fpants-cover.jpeg?alt=media&token=6894b0c7-53c6-446c-a0a1-1759f0595dd4",
    link: "/products/shorts-pants",
  },
  {
    id: 4,
    name: "Girls",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fgirls-cover.jpeg?alt=media&token=12a7c9f8-6ca8-4eef-9fa1-6912bcca1f0c",
    link: "/products/dresses",
  },
  {
    id: 5,
    name: "Caps/Hats",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fcap-cover.jpeg?alt=media&token=28e55733-6ee1-4196-bc7d-fcc9b2b99bb4",
    link: "/products/caps-hats",
  },
  {
    id: 6,
    name: "Bags",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fbag-cover.jpeg?alt=media&token=b041b2e5-6064-4625-bc64-28f17b61c720",
    link: "/products/bags",
  },
  {
    id: 7,
    name: "Athletics",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fathletics-cover.jpeg?alt=media&token=17bfbe64-2088-47a2-bcba-dcc78a44c0d6",
    link: "/products/athletics",
  },
  {
    id: 8,
    name: "Try My Best",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Ftry-my-best-cover.jpeg?alt=media&token=66203b4a-e1a6-4ea9-b760-411ddf60a190",
    link: "/products/trymybest",
  },
  {
    id: 9,
    name: "Collaborations",
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fcollaboration-cover.jpeg?alt=media&token=9699c407-351c-4146-9826-6b24e1e2fdf1",
    link: "/products/collaborations",
  },
];

export const siteConfigs = {
  mainImageUrl:
    "https://firebasestorage.googleapis.com/v0/b/blackhill-online.appspot.com/o/web-app%2Fimages%2Fmain-cover.jpeg?alt=media&token=0614e40c-c86f-4fc0-802f-5bf485639ed8",
};

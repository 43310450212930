import React from "react";
import { Typography } from "@material-ui/core";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import { siteConfigs } from "../data/data";

function WebSplash() {
  const history = useHistory();
  return (
    <>
      <motion.img
        src={siteConfigs.mainImageUrl}
        alt="splash cover"
        style={{
          width: "100vw",
          height: "100vh",
          minHeight: "100%",
          position: "fixed",
          top: 0,
          left: 0,
          objectFit: "cover",
          zIndex: 4,
        }}
        initial={{ opacity: 1 }}
        animate={{ opacity: 0, transition: { duration: 6, delay: 2 } }} // d
      />

      {/* text */}
      <motion.div
        style={{
          width: "100vw",
          height: "100vh",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          zIndex: 4,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 6, delay: 4 } }} // duration 6 delay 12
      >
        <Typography
          style={{
            lineHeight: "100vh",
            fontFamily: "Lato",
            color: "#fff",
          }}
          variant="h5"
          onClick={() => history.push("/category-selection")}
        >
          www.blackhillonline.com
        </Typography>
      </motion.div>

      {/* logo */}

      <motion.div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          zIndex: 0,
          backgroundColor: "#000",
        }}
      ></motion.div>
    </>
  );
}

export default WebSplash;

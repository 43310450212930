import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {
  Box,
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import firebase from "firebase/app";
import "firebase/firestore";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handleSubjectChange = (event) => {
    event.preventDefault();
    setSubject(event.target.value);
  };
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const clearForm = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
  };
  
  const sendEmail = async () => {
    try {
      const data = {
        to: 'info@blackhillonline.com',
        from: email,
        message: {
          subject: subject + ' - ' + name,
          text: `name: ${name}, email: ${email}\n\n` + message
        },
        createdAt: firebase.firestore.FieldValue.serverTimestamp()
      };
      console.log(data);
      const db = firebase.firestore();
      await db.collection("email").add(data);
      clearForm();
    } catch (err) {
      console.error(err);
    }
  };
  
  return (
    <Box>
      <Navbar />
      <Container fixed maxWidth="md" style={{ margin: "5rem auto" }}>
        {/* mobile view */}
        <Box display={{ xs: "block", sm: "none" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h5">
            Contact Us
          </Typography>
          <Box m={0} p={2}>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you have inquiries or other questions, please fill out the
              following form to contact us. Thank you.
            </Typography>
          </Box>

          <Box m={2}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="contact-us-name"
                label="Name"
                value={name}
                onChange={handleNameChange}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="contact-us-email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel htmlFor="contact-us-select">Subject</InputLabel>
              <Select
                id="contact-us-select"
                label="Subject"
                value={subject}
                onChange={handleSubjectChange}
              >
                <MenuItem value="production-information">
                  Production Information
                </MenuItem>
                <MenuItem value="delivery-status">Delivery Status</MenuItem>
                <MenuItem value="order-modification">
                  Order Modification
                </MenuItem>
                <MenuItem value="others">Others</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="contact-us-message"
                label="Message"
                multiline
                rows={12}
                // defaultValue="Default Value"
                value={message}
                onChange={handleMessageChange}
                variant="outlined"
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <Button
              disabled={!name || !email || !subject || !message}
              onClick={() => {
                sendEmail();

              }}
              color="primary"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </Box>

        {/* web view */}
        <Box display={{ xs: "none", sm: "block" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h5">
            Contact Us
          </Typography>
          <Box m={0} p={2}>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you have inquiries or other questions, please fill out the
              following form to contact us. Thank you.
            </Typography>
          </Box>

          <Box m={2}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="contact-us-name"
                label="Name"
                value={name}
                onChange={handleNameChange}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="contact-us-email"
                label="Email"
                value={email}
                onChange={handleEmailChange}
              />
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: "80%" }}>
              <InputLabel htmlFor="contact-us-select">Subject</InputLabel>
              <Select
                id="contact-us-select"
                label="Subject"
                value={subject}
                onChange={handleSubjectChange}
              >
                <MenuItem value="production-information">
                  Production Information
                </MenuItem>
                <MenuItem value="delivery-status">Delivery Status</MenuItem>
                <MenuItem value="order-modification">
                  Order Modification
                </MenuItem>
                <MenuItem value="others">Others</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box m={2}>
            <FormControl style={{ width: "80%" }}>
              <TextField
                id="contact-us-message"
                label="Message"
                multiline
                rows={12}
                // defaultValue="Default Value"
                value={message}
                onChange={handleMessageChange}
                variant="outlined"
              />
            </FormControl>
          </Box>

          <Box m={2}>
            <Button
              disabled={!name || !email || !subject || !message}
              onClick={() => {
                sendEmail();
              }}
              color="primary"
              variant="contained"
            >
              Send
            </Button>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default ContactUs;

import "./App.css";
import { Helmet } from "react-helmet";
import Splash from "./pages/Splash";
import Products from "./pages/Products";
import Profile from "./pages/Profile";
import Cart from "./pages/Cart";
import ProductsByCategory from "./pages/ProductsByCategory";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ReturnPolicy from "./components/ReturnPolicy";
import ShippingInformation from "./components/ShippingInformation";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProductByScroll from "./pages/ProductByScroll";
import CategorySelection from "./pages/CategorySelection";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import TermsAndConditions from "./components/TermsAndConditions";

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blackhill Online</title>
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/">
            <Splash />
          </Route>
          <Route exact path="/category-selection">
            <CategorySelection />
          </Route>
          <Route exact path="/products">
            <Products />
          </Route>
          <Route exact path="/products/:category">
            <ProductsByCategory />
          </Route>
          <Route exact path="/product/:productId">
            <ProductByScroll />
          </Route>
          <Route exact path="/profile">
            <Profile />
          </Route>
          <Route exact path="/cart">
            <Cart />
          </Route>
          <Route exact path="/shipping-information">
            <ShippingInformation />
          </Route>
          <Route exact path="/terms-n-conditions">
            <TermsAndConditions />
          </Route>
          <Route exact path="/return-policy">
            <ReturnPolicy />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/contact-us">
            <ContactUs />
          </Route>
          <Route exact path="/about-us">
            <AboutUs />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

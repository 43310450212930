import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function GirlLifestyleBigPocketsShortsSizeChart() {
  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="girl short size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="XS" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="40" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (cm)" />
                <ChartCell text="76" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Hip (cm)" />
                <ChartCell text="106" />
              </TableRow>
              <TableRow>
                <LeftChartCell text=" Leg Opening (cm)" />
                <ChartCell text="54" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={14} display={{ xs: "none", sm: "block" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="girl short size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="XS" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="40" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (cm)" />
                <ChartCell text="76" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Hip (cm)" />
                <ChartCell text="106" />
              </TableRow>
              <TableRow>
                <LeftChartCell text=" Leg Opening (cm)" />
                <ChartCell text="54" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default GirlLifestyleBigPocketsShortsSizeChart;

import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function ILoveTrailRunningTeeSizeChart() {
  const XS = "XS";
  const S = "S";
  const M = "M";
  const L = "L";
  const XL = "XL";

  const XSWaist = '27"-28"';
  const SWaist = '29"-30"';
  const MWaist = '31"-32"';
  const LWaist = '33"-34"';
  const XLWaist = '33"-34"';

  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="i love trail running tee size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size (Slim Fit)" />
                <ChartCell text={XS} />
                <ChartCell text={S} />
                <ChartCell text={M} />
                <ChartCell text={L} />
                <ChartCell text={XL} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Half Chest (cm)" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="51" />
                <ChartCell text="54" />
                <ChartCell text="55" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Bottom Width (cm)" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="51" />
                <ChartCell text="54" />
                <ChartCell text="55" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Shoulder Width (cm)" />
                <ChartCell text="41.5" />
                <ChartCell text="45" />
                <ChartCell text="45.5" />
                <ChartCell text="46.5" />
                <ChartCell text="47.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="58" />
                <ChartCell text="62.5" />
                <ChartCell text="65" />
                <ChartCell text="68" />
                <ChartCell text="68" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={8} display={{ xs: "none", sm: "block" }}>
      <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="i love trail running tee size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size (Slim Fit)" />
                <ChartCell text={XS} />
                <ChartCell text={S} />
                <ChartCell text={M} />
                <ChartCell text={L} />
                <ChartCell text={XL} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Half Chest (cm)" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="51" />
                <ChartCell text="54" />
                <ChartCell text="55" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Bottom Width (cm)" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="51" />
                <ChartCell text="54" />
                <ChartCell text="55" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Shoulder Width (cm)" />
                <ChartCell text="41.5" />
                <ChartCell text="45" />
                <ChartCell text="45.5" />
                <ChartCell text="46.5" />
                <ChartCell text="47.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="58" />
                <ChartCell text="62.5" />
                <ChartCell text="65" />
                <ChartCell text="68" />
                <ChartCell text="68" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default ILoveTrailRunningTeeSizeChart;

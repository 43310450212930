import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
  Drawer,
} from "@material-ui/core";
import logo from "../images/logo.png";
import drawerBackground from "../images/drawer.jpg";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { sections } from "../data/data";
import { motion } from "framer-motion";
import { FadeInStaggerItemVariants } from "../animations/FadeIn";
import { useCartItems } from "../hooks/CartHooks";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

function Navbar() {
  const [cartItems, setCartItems] = useCartItems();

  const classes = useStyles();
  const history = useHistory();
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  useEffect(() => {}, [cartItems]);

  return (
    <div>
      <AppBar
        position="fixed"
        color="secondary"
        style={{ background: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <Button
            disableFocusRipple
            disableRipple
            edge="start"
            color="inherit"
            aria-label="menu"
            href="/category-selection"
          >
            <img
              src={logo}
              alt="logo"
              style={{ width: "10vw", minWidth: "100px", maxWidth: "200px" }}
            />
          </Button>
          <Typography variant="h6" className={classes.title}></Typography>
          <IconButton edge="end" onClick={() => history.push("/cart")}>
            <ShoppingCartOutlinedIcon />
          </IconButton>
          <Box display={{ xs: "block", sm: "none" }}>
            <IconButton edge="end" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer anchor="top" open={openDrawer} onClose={toggleDrawer(false)}>
        <Box
          style={{
            width: "100vw",
            height: "100vh",
            backgroundImage: `url(${drawerBackground})`,
            backgroundSize: "cover",
          }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {sections.map((s) => (
            <Button
              key={s.id}
              onClick={() => setOpenDrawer(false)}
              href={s.link}
            >
              <motion.Typography
                variants={FadeInStaggerItemVariants}
                initial="hidden"
                animate="show"
                style={{
                  color: "white",
                  fontSize: "1.2rem",
                  fontFamily: "Manrope",
                  lineHeight: "2.4rem",
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                {s.name}
              </motion.Typography>
            </Button>
          ))}
        </Box>
      </Drawer>
    </div>
  );
}

export default Navbar;

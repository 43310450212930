import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../contexts/CartContext";
import { useMutation } from "@apollo/client";
import HighlightOffRoundedIcon from "@material-ui/icons/HighlightOffRounded";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import RemoveCircleOutlineRoundedIcon from "@material-ui/icons/RemoveCircleOutlineRounded";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import {
  Box,
  Container,
  Button,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";
import styled from "styled-components";
import Navbar from "../components/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import { CheckoutCreate } from "../api/graphql";
import { useCartItems } from "../hooks/CartHooks";
import EmptyCart from "../components/EmptyCart";
import CheckoutButton from "../components/CheckoutButton";
import Footer from "../components/Footer";

const StyledBox = styled(Box)`
  background-color: #f0f0f0;
`;

function Cart() {
  const [cartItems, setCartItems] = useCartItems();
  const [removeItemIndex, setRemoveItemIndex] = useState(null);

  const clearCart = () => {
    setCartItems([]);
  };

  useEffect(() => {
    const removeCartItems = () => {
      const originalCartItems = [...cartItems];
      if (
        removeItemIndex == null ||
        removeItemIndex >= originalCartItems.length
      ) {
        return;
      }
      originalCartItems.splice(removeItemIndex, 1);
      setCartItems(originalCartItems);
    };
    removeCartItems();
  }, [removeItemIndex, setCartItems]);

  return (
    <Container fixed maxWidth="lg" style={{ margin: "5rem auto 2rem" }}>
      <Navbar />

      {cartItems && cartItems.length ? (
        <Box>
          {/* web view */}
          <Box display={{ xs: "none", sm: "block" }} style={{ marginBottom: '5rem'}}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "black" }}>
                    <TableCell style={{ color: "white" }} align="center">
                      <Typography variant="subtitle1">Items</Typography>
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      <Typography variant="subtitle1">
                        Items Description
                      </Typography>
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      <Typography variant="subtitle1">Quantity</Typography>
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      <Typography variant="subtitle1">Amount</Typography>
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      <Typography variant="subtitle1">Remove Item</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartItems.map((cartItem, i) => (
                    <TableRow key={i}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "20%" }}
                      >
                        <Box>
                          <img
                            style={{ width: "80%" }}
                            src={cartItem.imageUrl}
                            alt="image"
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box m={1}>
                          <Typography variant="subtitle1">
                            {cartItem.title}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          m={1}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Button
                            onClick={() => {
                              setCartItems(minusQuantity(i, cartItems));
                            }}
                          >
                            <RemoveCircleOutlineRoundedIcon />
                          </Button>
                          <Typography variant="body1">
                            {cartItem.quantity}
                          </Typography>
                          <Button
                            onClick={() => {
                              setCartItems(addQuantity(i, cartItems));
                            }}
                          >
                            <AddCircleOutlineRoundedIcon />
                          </Button>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          m={1}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography variant="body1">
                            {cartItem.price.currencyCode}{" "}
                            {getSubTotal(cartItem)}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box
                          m={1}
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Button
                            onClick={() => {
                              console.log("now remove i", i);
                              setRemoveItemIndex(i);
                            }}
                          >
                            <HighlightOffRoundedIcon />
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" align="center">
                      <strong>TOTAL: {getTotal(cartItems)}</strong>
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
            <CheckoutButton />
          </Box>
          {/* mobile view */}
          <Box display={{ xs: "block", sm: "none" }} style={{ marginBottom: '5rem'}}>
            <TableContainer component={Paper} elevation={1}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "black" }}>
                    <TableCell style={{ color: "white" }} align="center">
                      <Typography variant="subtitle1">Items</Typography>
                    </TableCell>
                    <TableCell style={{ color: "white" }} align="center">
                      <Typography variant="subtitle1">
                        Items Description
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cartItems.map((lineItem, i) => (
                    <TableRow key={i}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ width: "30%" }}
                      >
                        <Box>
                          <img
                            style={{ width: "100%" }}
                            src={lineItem.imageUrl}
                            alt="image"
                          />
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        <Box m={1}>
                          <Typography variant="subtitle1">
                            <strong>{lineItem.title}</strong>
                          </Typography>
                          <Typography variant="subtitle1">
                            Qty:
                            <Button
                              variant="text"
                              disableElevation
                              style={{
                                maxWidth: "30px",
                                minWidth: "30px",
                                // maxHeight: "30px",
                                // minHeight: "30px",
                                margin: "0.5rem",
                              }}
                              // size="small"
                              onClick={() => {
                                setCartItems(minusQuantity(i, cartItems));
                              }}
                            >
                              -{" "}
                            </Button>
                            {lineItem.quantity}{" "}
                            <Button
                              variant="text"
                              disableElevation
                              style={{
                                maxWidth: "30px",
                                minWidth: "30px",
                                margin: "0.5rem",
                              }}
                              // size="small"
                              onClick={() => {
                                setCartItems(addQuantity(i, cartItems));
                              }}
                            >
                              +{" "}
                            </Button>
                            <Button
                              variant="text"
                              disableElevation
                              style={{
                                maxWidth: "30px",
                                minWidth: "30px",
                                margin: "0.5rem",
                              }}
                              onClick={() => {
                                console.log("now remove i", i);
                                setRemoveItemIndex(i);
                              }}
                            >
                              <DeleteForeverRoundedIcon />{" "}
                            </Button>
                          </Typography>

                          <Typography variant="subtitle1">
                            {lineItem.price.currencyCode}{" "}
                            {getSubTotal(lineItem)}
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" align="center">
                        <strong>TOTAL: {getTotal(cartItems)}</strong>
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <CheckoutButton />
          </Box>
        </Box>
      ) : (
        <EmptyCart />
      )}

      <Footer />
    </Container>
  );
}

export default Cart;

function addQuantity(i, cartItems) {
  const items = [...cartItems];
  items[i].quantity++;
  return items;
}

function minusQuantity(i, cartItems) {
  const items = [...cartItems];
  const currentQuantity = items[i].quantity;
  if (currentQuantity > 1) {
    items[i].quantity--;
  }
  return items;
}

function getSubTotal(cartItem) {
  const { quantity, price } = cartItem;
  const amount = parseFloat(price.amount);
  return quantity * amount;
}

function getTotal(cartItems) {
  const total = cartItems.reduce((acc, cur) => acc + getSubTotal(cur), 0);
  return total;
}

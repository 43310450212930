import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {
  Container,
  Grid,
  Hidden,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  Dialog,
  IconButton,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CreateCheckout } from "../api/graphql";

import { useCartItems } from "../hooks/CartHooks";
import CheckingOut from "./CheckingOut";
import Xpac1 from "../images/X-Pac-1.jpg";
import Xpac2 from "../images/X-Pac-2.jpg";
import SizeCharts from "./SizeCharts";
import Descriptions from "./Descriptions";

function ProductInformationBox({ product, images }) {
  /* force check size avaliability */
  useEffect(() => {
    handleSizeChange({ target: { value: avaliableSize[0] } });
  }, []);

  const productVariantsData = getProductVariantsData(product);

  const avaliableSize = productVariantsData.map((p) => p.Size);
  const [soldOut, setSoldOut] = useState(false);
  const [size, setSize] = useState(avaliableSize[0]);
  const [quantity, setQuantity] = useState(1);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [openAddToCartDialog, setOpenAddToCartDialog] = useState(false);
  const [openAddToCartSnackbar, setOpenAddToCartSnackbar] = useState(false);

  const handleSizeChange = (e) => {
    const size = e.target.value;
    // console.log("size change", size);
    setSize(size);
    const filteredProduct = productVariantsData.filter((v) => v.Size === size);
    // console.log("filteredProduct", filteredProduct);
    if (filteredProduct.length && filteredProduct[0].quantityAvailable === 0) {
      setSoldOut(true);
    } else {
      setSoldOut(false);
    }
  };

  const handleQuantityChange = (e) => setQuantity(e.target.value);

  // modal show image
  const [imageIndex, setImageIndex] = useState(0);
  const showModal = (i) => {
    setImageIndex(i);
    setOpenImageDialog(true);
  };

  const [cartItems, setCartItems] = useCartItems();
  const [checkout, { loading: checkoutLoading, error: checkoutError }] =
    useMutation(CreateCheckout, {
      variables: {
        variantId: getProductVariantIdBySize(productVariantsData, size),
        quantity: quantity,
      },
      onCompleted: (data) => {
        console.log("checkout completed", data);
        window.location.href = data.checkoutCreate.checkout.webUrl;
      },
    });

  const addToCart = () => {
    // setOpenAddToCartDialog(true);
    setOpenAddToCartSnackbar(true);

    const lineItem = {
      variantId: getProductVariantIdBySize(productVariantsData, size),
      quantity: quantity,
      // extra info for cart display
      imageUrl: product.images.edges[0].node.src,
      title: product.title,
      price: product.variants.edges[0].node.price,
    };
    // setLineItems([lineItem, ...lineItems]);
    setCartItems([lineItem, ...cartItems]);
  };

  if (checkoutLoading) {
    console.log("checkout loading");
  }

  if (checkoutError) {
    console.log("checkout error");
  }
  //   checking out dialog
  const [openCheckingOut, setOpenCheckingOut] = useState(false);
  const handleCloseCheckingOut = () => {
    setOpenCheckingOut(false);
  };

  // console.log("product info", product);
  return (
    <Container>
      <Container fixed maxWidth="lg">
        {/* web view product title */}
        <Box
          m={1}
          display={{ xs: "none", sm: "block" }}
          style={{ marginTop: "5rem", marginBottom: "5rem" }}
        >
          <Typography
            style={{
              fontFamily: "Manrope",
              fontWeight: "bolder",
              fontSize: "2rem",
            }}
            variant="h4"
            gutterBottom
          >
            {product.title}
          </Typography>
        </Box>

        {/* mobile view product title */}
        <Box
          m={0}
          display={{ xs: "block", sm: "none" }}
          style={{ marginBottom: "2rem" }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "1.2rem",
            }}
            variant="h4"
            gutterBottom
          >
            {product.title}
          </Typography>
        </Box>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            {/* Images */}

            {product && (
              <>
                {/* web view images */}
                <Box
                  m={2}
                  display={{
                    xs: "none",
                    sm: "block",
                  }}
                >
                  {images &&
                    images.map((image, i) => (
                      <img
                        key={i}
                        cols={1}
                        rows={1}
                        onClick={() => showModal(i)}
                        style={{
                          margin: "1rem",
                          width: product.title.includes("Try") ? "5vw" : "8vw",
                          height: product.title.includes("Try") ? "5vw" : "8vw",
                          objectFit: "cover",
                          minWidth: product.title.includes("Try")
                            ? "50px"
                            : "80px",
                          minHeight: product.title.includes("Try")
                            ? "50px"
                            : "80px",
                          maxWidth: "100px",
                          maxHeight: "100px",
                          borderRadius: "50%",
                        }}
                        src={image.node.src}
                        alt="product"
                      />
                    ))}
                </Box>

                {/* mobile view images */}
                <Box
                  m={0}
                  display={{
                    xs: "block",
                    sm: "none",
                  }}
                >
                  {images &&
                    images.map((image, i) => (
                      <img
                        key={i}
                        cols={1}
                        rows={1}
                        onClick={() => showModal(i)}
                        style={{
                          margin: "1rem",
                          width: "16vw",
                          height: "16vw",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                        src={image.node.src}
                        alt="product"
                      />
                    ))}
                </Box>

                {/* X-Pac */}
                {isXPac(productVariantsData[0].Fabric) && (
                  <div>
                    <Box
                      display={{
                        xs: "none",
                        sm: "block",
                      }}
                      m={2}
                    >
                      <img
                        src={Xpac1}
                        alt="x-pac label"
                        style={{
                          margin: "1rem",
                          width: "20%",
                          minWidth: "80px",
                        }}
                      />

                      <img
                        src={Xpac2}
                        alt="x-pac label"
                        style={{
                          margin: "1rem",
                          width: "20%",
                          minWidth: "80px",
                          // borderRadius: "50%",
                        }}
                      />
                    </Box>
                    <Box
                      display={{
                        xs: "block",
                        sm: "none",
                      }}
                      m={0}
                    >
                      <img
                        src={Xpac1}
                        alt="x-pac label"
                        style={{
                          margin: "1rem",
                          width: "20%",
                          minWidth: "60px",
                        }}
                      />

                      <img
                        src={Xpac2}
                        alt="x-pac label"
                        style={{
                          margin: "1rem",
                          width: "20%",
                          minWidth: "60px",
                        }}
                      />
                    </Box>
                  </div>
                )}
              </>
            )}

            {/* Description  */}
            {product && <Descriptions product={product} />}

            {/* Size Chart */}
            {product && <SizeCharts title={product.title} />}
          </Grid>

          {/* Prodcut info - color, size, fabric, price */}
          <Grid item xs={12} sm={3} style={{ marginTop: "0rem" }}>
            {/* Color */}
            {productVariantsData[0].Color && (
              <Box m={5}>
                <Typography variant="subtitle2">
                  <strong>COLOR:</strong>
                </Typography>
                <Typography variant="body1">
                  {productVariantsData[0].Color}
                </Typography>
              </Box>
            )}

            {/* Size */}
            {productVariantsData[0].Size && (
              <Box m={5}>
                <Typography variant="subtitle2">
                  <strong>SIZE:</strong>
                </Typography>
                <Typography variant="body1">
                  {avaliableSize.join(",")}
                </Typography>
              </Box>
            )}

            {/* Fabric */}
            {productVariantsData[0].Fabric && (
              <Box m={5}>
                <Typography variant="subtitle2">
                  <strong>FABRIC:</strong>
                </Typography>
                <Typography variant="body1">
                  {productVariantsData[0].Fabric}
                </Typography>
              </Box>
            )}

            {/* Price */}
            {product && (
              <Box m={5}>
                <Typography variant="subtitle2">
                  <strong>PRICE:</strong>
                </Typography>
                <Typography variant="body1">
                  {formatPrice(product.variants.edges[0].node.price)}
                </Typography>
              </Box>
            )}
          </Grid>

          {/* User input - size, quantity */}
          <Grid item xs={12} sm={3} style={{ marginTop: "3rem" }}>
            {/* input size */}
            <Box m={2}>
              <FormControl
                style={{ width: "100%", minWidth: "120px", height: "5rem" }}
              >
                <InputLabel id="size-select-label">SIZE</InputLabel>
                <Select
                  labelId="size-select-label"
                  id="size-select"
                  value={size}
                  onChange={handleSizeChange}
                >
                  {avaliableSize &&
                    avaliableSize.map((size) => (
                      <MenuItem key={size} value={size}>
                        {size}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>

            {/* input quantity */}
            <Box m={2}>
              <FormControl
                style={{ width: "100%", minWidth: "120px", height: "5rem" }}
              >
                <InputLabel id="quantity-select-label">QUANTITY</InputLabel>
                <Select
                  labelId="quantity-select-label"
                  id="quantity-select"
                  value={quantity}
                  onChange={handleQuantityChange}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/* add to cart */}
            <Box m={2}>
              <Button
                variant={"outlined"}
                disabled={soldOut}
                disableElevation
                color="primary"
                style={{
                  margin: "1rem auto",
                  width: "100%",
                  minWidth: "120px",
                  height: "5rem",
                }}
                onClick={addToCart}
              >
                {soldOut ? "Sold Out" : "Add to Cart"}
              </Button>
            </Box>

            {/* checkout */}
            <Box m={2}>
              <Button
                variant={"contained"}
                disabled={soldOut}
                disableElevation
                color="primary"
                style={{
                  margin: "1rem auto",
                  width: "100%",
                  minWidth: "120px",
                  height: "5rem",
                }}
                onClick={() => {
                  setOpenCheckingOut(true);
                  checkout();
                }}
              >
                {soldOut ? "Sold Out" : "Checkout"}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        // fullWidth={true}
        maxWidth="lg"
        open={openImageDialog}
        onClose={() => setOpenImageDialog(false)}
        // style={{ padding: 0, margin: 0}}
      >
        {/* mobile view */}
        <Hidden smUp>
          <img
            onClick={() =>
              setImageIndex((imageIndex) => (imageIndex + 1) % images.length)
            }
            style={{
              objectFit: "cover",
              overflow: "hidden",
              width: "100%",
            }}
            src={images[imageIndex].node.src}
            alt="product shot"
          />
        </Hidden>

        {/* web view */}
        <Hidden xsDown>
          <img
            onClick={() =>
              setImageIndex((imageIndex) => (imageIndex + 1) % images.length)
            }
            style={{
              objectFit: "cover",
              overflow: "hidden",
              height: "80vh",
              width: "80vh",
            }}
            src={images[imageIndex].node.src}
            alt="product shot"
          />
        </Hidden>
      </Dialog>

      <Dialog
        // fullWidth={true}
        maxWidth="lg"
        open={openAddToCartDialog}
        onClose={() => setOpenAddToCartDialog(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          m={10}
        >
          <Typography variant="h5" align="center">
            Add to Cart
          </Typography>
        </Box>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openAddToCartSnackbar}
        autoHideDuration={5000}
        onClose={() => setOpenAddToCartSnackbar(false)}
        message="Your item has been added to cart"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => setOpenAddToCartSnackbar(false)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      ></Snackbar>

      <Dialog
        fullScreen
        onClose={handleCloseCheckingOut}
        open={openCheckingOut}
      >
        <CheckingOut />
      </Dialog>
    </Container>
  );
}

export default ProductInformationBox;

// helper functions
function getProductVariantsData(product) {
  const { variants } = product;
  const productVariantsData = [];
  // console.log('variants', variants.edges);
  variants.edges.map((variant) => {
    const node = variant.node;
    // console.log(node.id);
    const { id, selectedOptions, quantityAvailable } = node;
    /**
     * selectedOptions [
     *  {name: color, value: black},
     *  {name: size, value: s},
     *  {name: fabric, value: cotton}
     * ]
     *
     * =>
     *
     * variant: {
     *  id: xxx,
     *  color: black,
     *  size: s,
     *  fabric: cotton
     * }
     */

    const resultVariant = {
      id,
      quantityAvailable,
    };

    selectedOptions.map((option) => {
      resultVariant[option.name] = option.value;
    });

    productVariantsData.push(resultVariant);
  });

  return productVariantsData;
}

function getProductVariantIdBySize(productVariantsData, size) {
  const filteredData = productVariantsData.filter((data) => data.Size === size);
  return filteredData[0].id;
}

function formatPrice(price) {
  return (
    <div>
      {price.currencyCode}: {price.amount}
    </div>
  );
}

function isXPac(fabric) {
  const X_PAC_KEYWORD = "X-Pac";
  if (fabric.split(" ")[0] === X_PAC_KEYWORD) {
    return true;
  }
  return false;
}

import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { sections, siteConfigs } from "../data/data";

const variants = {
  enter: {
    opacity: 0,
    transition: { duration: 2 },
  },
  center: {
    zIndex: 0,
    opacity: 1,
    transition: { duration: 2 },
  },
  exit: {
    zIndex: 0,
    opacity: 0,
    transition: { duration: 2 },
  },
};

const list = {
  visible: {
    opacity: 1,
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    // transition: {
    //   when: "afterChildren",
    // },
  },
};

const listItem = {
  visible: {
    opacity: 1,
    y: 0,

    transition: { delay: 1, duration: 0.6 },
  },
  hidden: {
    opacity: 0,
    y: -20,
  },
};

const StyledLiVariants = {
  hover: {
    x: 10,
    transition: { duration: 0.3 },
  },
};

const StyledImg = styled(motion.img)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const StyledUl = styled(motion.ul)`
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
`;

const StyledLi = styled(motion.li)`
  /* width: auto; */
  list-style: none;
  display: block;
  overflow: hidden;
  margin: 1.6rem;
  height: 1.6rem;
  text-align: left;
  font-family: "Manrope";
  font-weight: 300;
  font-size: 1rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: white;
  font-weight: bold;
`;

// const FadeInVariant = {
//     initial: {opacity: 0},
//     animate: {opacity: 1, transition: {duration: 1}}
// }
function WebCategorySelection() {
  const [imageIndex, setImageIndex] = useState(0);

  return (
    <AnimatePresence
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
      style={{ overflow: "hidden" }}
    >
      <StyledImg
        key={imageIndex}
        variants={variants}
        // initial="enter"
        // animate="center"
        // exit="exit"
        initial={{ opacity: 0, zIndex: 0, transition: { duration: 1 } }}
        animate={{ opacity: 1, zIndex: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0, zIndex: 0, transition: { duration: 1 } }}
        src={sections[imageIndex].imageUrl}
        // src={`images/${sections[imageIndex].imageUrl}`}
        alt="background"
      />
      <motion.div
        style={{
          position: "absolute",
          top: "25%",
          left: "10%",
          transform: "translate(-20%, 20%)",
          width: "200px",
          height: "600px",
          zIndex: 10,
          backgroundColor: "rgba(0,0,0,0.75)",
          overflow: "hidden",
        }}
        initial={{ y: "-100vh", opacity: 0 }}
        animate={{ y: "-20%", opacity: 1, transition: { duration: 1 } }}
      >
        <img
          style={{
            width: "120px",
            position: "absolute",
            left: 0,
            margin: "1.6rem",
            filter: "invert(1)",
            opacity: 0.8,
          }}
          src={logo}
          alt="logo"
        />
        <StyledUl
          style={{ marginTop: "6rem" }}
          variants={list}
          initial="hidden"
          animate="visible"
          // exit="hidden"
        >
          {sections.map((section, i) => (
            <StyledLi
              key={section.id}
              variants={listItem}
              onHoverStart={() => setImageIndex(section.id)}
              //   onHoverEnd={() => setImageIndex(0)}
            >
              <StyledLink to={section.link}>{section.name}</StyledLink>
            </StyledLi>
          ))}
        </StyledUl>
      </motion.div>
    </AnimatePresence>
  );
}

export default WebCategorySelection;

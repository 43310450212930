import React from "react";
import { Box, Typography } from "@material-ui/core";
import WebCategorySelection from "../components/WebCategorySelection";
import MobileCategorySelection from "../components/MobileCategorySelection";


function CategorySelection() {
  return (
    <>
      {/* web */}
      <Box display={{ xs: "none", sm: "block" }}>
        <WebCategorySelection />
      </Box>

      {/* mobile */}
      <Box display={{ xs: "block", sm: "none" }}>
        <MobileCategorySelection />
      </Box>
    </>
  );
}

export default CategorySelection;

import { Box, Typography } from "@material-ui/core";

function WildpiLongSleevesDescription() {
  return (
    <>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          當你喜歡探索更多大自然的不同面貌，慢慢就會發現很多角落以前都還沒有機會去過，親身看過之後才懂珍惜香港獨特的美。
        </Typography>
      </Box>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          這次原野π聯乘了本地品牌Blackhill 推出一系列的香港風景T恤，以sunrise ,
          island
          及starlight三款圖案展現香港的壯麗景色，圖案表面再印上地圖的等高線花紋，展現圖中的獨有地貌。
        </Typography>
      </Box>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          時代急速轉變，很多風景再過幾年可能都不再一樣，這次合作有幸記錄這個時代的香港，願更加多人也懂得欣賞香港的美，一起愛惜這個環境。
        </Typography>
      </Box>
    </>
  );
}

export default WildpiLongSleevesDescription;

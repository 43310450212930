import React from "react";
import {
  TableContainer,
  TableRow,
  TableCell,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

function LongSleevesSizeChart() {
  return (
    <div>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="pants size chart">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Size
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  S
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  M
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  L
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  XL
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Half Chest (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  49
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  51.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  54
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  56.5
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Half Bottom (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  49
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  51.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  54
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  56.5
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Sleeve Length (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  60.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  61
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  61.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  62
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Shoulder (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  51
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  53
                </TableCell>{" "}
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  55
                </TableCell>{" "}
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  57
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Length (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  72
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  72.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  73
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  73.5
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={14} display={{ xs: "none", sm: "block" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="pants size chart">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Size
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  S
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  M
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  L
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  XL
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Half Chest (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  49
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  51.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  54
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  56.5
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Half Bottom (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  49
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  51.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  54
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  56.5
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Sleeve Length (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  60.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  61
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  61.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  62
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Shoulder (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  51
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  53
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  55
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  57
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  align="left"
                  style={{
                    padding: 2,
                    paddingLeft: "1rem",
                    fontsize: "0.8rem",
                  }}
                >
                  Length (cm)
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  72
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  72.5
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  73
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    padding: 2,
                    fontsize: "0.8rem",
                  }}
                >
                  73.5
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  );
}

export default LongSleevesSizeChart;

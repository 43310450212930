import React from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getProductDetailLink } from "../utils/product";

const gridBoxImageVariants = {
  initial: { scale: 0.8, transition: { duration: 1 } },
  hover: { scale: 0.85, transition: { duration: 1 } },
};

const GridBoxWrapper = styled(motion.div)`
  background-color: #f5f5f5;
  position: relative;
  width: 90%;
  margin: 1rem;
`;

const GridBoxImage = styled(motion.img)`
  width: 100%;
  filter: drop-shadow(8px 12px 2px #ddd);
`;

function MobileProductGridBox({ product }) {
  const history = useHistory();

  return (
    <GridBoxWrapper
      whileHover="hover"
      onClick={() => history.push(getProductDetailLink(product.node.id))}
    >
      <GridBoxImage
        src={product.node.images.edges[0].node.src}
        alt="product"
        initial="initial"
        variants={gridBoxImageVariants}
      />
    </GridBoxWrapper>
  );
}

export default MobileProductGridBox;

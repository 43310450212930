import { useEffect, useState } from "react";

export const useCartItems = () => {
  const [cartItems, setCartItems] = useState(() => {
    const persistCartItems = window.localStorage.getItem('cartItems');
    return persistCartItems !== null
      ? JSON.parse(persistCartItems)
      : [];
  });
  
  useEffect(() => {
    window.localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems, setCartItems]);

  return [cartItems, setCartItems];
}
import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function AlmightyJacketSizeChart() {
  const XXS = 'XXS';
  const XS = 'XS';
  const S = 'S';
  const M = 'M';
  const L = 'L';
  const XL = 'XL';

  const XXSWaist = '24"-26"';
  const XSWaist = '26"-28"';
  const SWaist = '28"-30"';
  const MWaist = '30"-32"';
  const LWaist = '32"-34"';
  const XLWaist = '34"-36"';

  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="almighty jacket size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text={XXS} />
                <ChartCell text={XS} />
                <ChartCell text={S} />
                <ChartCell text={M} />
                <ChartCell text={L} />
                <ChartCell text={XL} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Chest - Half (cm)" />
                <ChartCell text="49" />
                <ChartCell text="51" />
                <ChartCell text="53" />
                <ChartCell text="55" />
                <ChartCell text="57" />
                <ChartCell text="59" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Bottom - Half (cm)" />
                <ChartCell text="47" />
                <ChartCell text="49" />
                <ChartCell text="51" />
                <ChartCell text="53" />
                <ChartCell text="55" />
                <ChartCell text="57" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="63.5" />
                <ChartCell text="65.5" />
                <ChartCell text="67.5" />
                <ChartCell text="69.5" />
                <ChartCell text="71.5" />
                <ChartCell text="72.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Shoulder (cm)" />
                <ChartCell text="12" />
                <ChartCell text="12.5" />
                <ChartCell text="13" />
                <ChartCell text="13.5" />
                <ChartCell text="14" />
                <ChartCell text="14.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Sleeves (cm)" />
                <ChartCell text="63.5" />
                <ChartCell text="64.5" />
                <ChartCell text="65.5" />
                <ChartCell text="67" />
                <ChartCell text="68.5" />
                <ChartCell text="69.5" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={8} display={{ xs: "none", sm: "block" }}>
      <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="almighty jacket size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text={XXS} />
                <ChartCell text={XS} />
                <ChartCell text={S} />
                <ChartCell text={M} />
                <ChartCell text={L} />
                <ChartCell text={XL} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Chest - Half (cm)" />
                <ChartCell text="49" />
                <ChartCell text="51" />
                <ChartCell text="53" />
                <ChartCell text="55" />
                <ChartCell text="57" />
                <ChartCell text="59" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Bottom - Half (cm)" />
                <ChartCell text="47" />
                <ChartCell text="49" />
                <ChartCell text="51" />
                <ChartCell text="53" />
                <ChartCell text="55" />
                <ChartCell text="57" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="63.5" />
                <ChartCell text="65.5" />
                <ChartCell text="67.5" />
                <ChartCell text="69.5" />
                <ChartCell text="71.5" />
                <ChartCell text="72.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Shoulder (cm)" />
                <ChartCell text="12" />
                <ChartCell text="12.5" />
                <ChartCell text="13" />
                <ChartCell text="13.5" />
                <ChartCell text="14" />
                <ChartCell text="14.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Sleeves (cm)" />
                <ChartCell text="63.5" />
                <ChartCell text="64.5" />
                <ChartCell text="65.5" />
                <ChartCell text="67" />
                <ChartCell text="68.5" />
                <ChartCell text="69.5" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default AlmightyJacketSizeChart;

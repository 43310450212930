import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function PantsSizeChart() {
  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="pants size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="S" />
                <ChartCell text="M" />
                <ChartCell text="L" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="96" />
                <ChartCell text="98.6" />
                <ChartCell text="101" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (cm)" />
                <ChartCell text="85" />
                <ChartCell text="89" />
                <ChartCell text="94" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Front Rise (cm)" />
                <ChartCell text="33" />
                <ChartCell text="33.6" />
                <ChartCell text="34.2" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Leg Opening (cm)" />
                <ChartCell text="20.5" />
                <ChartCell text="21.5" />
                <ChartCell text="22.5" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={14} display={{ xs: "none", sm: "block" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="pants size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="S" />
                <ChartCell text="M" />
                <ChartCell text="L" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="96" />
                <ChartCell text="98.6" />
                <ChartCell text="101" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (cm)" />
                <ChartCell text="85" />
                <ChartCell text="89" />
                <ChartCell text="94" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Front Rise (cm)" />
                <ChartCell text="33" />
                <ChartCell text="33.6" />
                <ChartCell text="34.2" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Leg Opening (cm)" />
                <ChartCell text="20.5" />
                <ChartCell text="21.5" />
                <ChartCell text="22.5" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default PantsSizeChart;

import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GetProductsByTypeGql } from "../api/graphql";

// components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CategorySelector from "../components/CategorySelector";
import Loading from "../components/Loading";
import EmptyProductTypes from "../components/EmptyProductTypes";

// ui
import { Box, Container } from "@material-ui/core";
import { motion } from "framer-motion";
import { getProductDetailLink } from "../utils/product";

// import TryMyBestCoverImageModal from "../components/TryMyBestCoverImageModal";

function ProductsByCategory() {
  const history = useHistory();
  const category = useLocation().pathname.split("/").pop();
  const { loading, error, data } = useQuery(GetProductsByTypeGql(category));

  if (loading) return <Loading />;
  if (error) return <p>Error: {error.message}</p>;

  // console.log('category', category, "product cat", data);
  const orderedProducts = [...data.products.edges].reverse();

  return (
    <>
      {/* <TryMyBestCoverImageModal /> */}
      <Box>
        <Navbar />

        <Box display={{ xs: "none", sm: "block" }}>
          <CategorySelector />
        </Box>

        <Container maxWidth="lg" style={{ margin: "5rem auto" }}>
          {/* web view */}
          <Box display={{ xs: "none", sm: "block" }}>
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
            >
              {orderedProducts.length ? (
                orderedProducts.map((product, i) => (
                  <motion.div
                    key={"_" + i}
                    style={{
                      backgroundColor: "#f5f5f5",
                      position: "relative",
                      width: "30%",
                      minWidth: "300",
                      margin: "0.6rem",
                    }}
                    whileHover="hover"
                    onClick={() => history.push(getProductDetailLink(product.node.id))}
                  >
                    <motion.div
                      style={{
                        position: "absolute",
                        top: "1rem",
                        left: "1rem",
                        zIndex: 10,
                        fontWeight: "bolder",
                      }}
                      initial="initial"
                      variants={{
                        initial: { opacity: 0 },
                        hover: { opacity: 1, transition: { duration: 2 } },
                      }}
                    >
                      {product.node.title}
                    </motion.div>

                    <motion.img
                      src={product.node.images.edges[0].node.src}
                      alt="product"
                      style={{
                        width: "100%",
                        filter: "drop-shadow(8px 12px 2px #ddd)",
                      }}
                      initial="initial"
                      variants={{
                        initial: { scale: 0.8, transition: { duration: 1 } },
                        hover: { scale: 0.85, transition: { duration: 1 } },
                      }}
                    />
                  </motion.div>
                ))
              ) : (
                <EmptyProductTypes />
              )}
            </Box>
          </Box>

          {/* mobile view */}
          <Box
            display={{
              xs: "block",
              sm: "none",
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {orderedProducts.length ? (
                orderedProducts.map((product, i) => (
                  <motion.div
                    key={product.node.id}
                    style={{
                      backgroundColor: "#f5f5f5",
                      position: "relative",
                      width: "90%",
                      // minWidth: '200',
                      margin: "1rem",
                    }}
                    whileHover="hover"
                    onClick={() => history.push(getProductDetailLink(product.node.id))}
                  >
                    <motion.img
                      src={product.node.images.edges[0].node.src}
                      alt="product"
                      style={{
                        width: "100%",
                        filter: "drop-shadow(8px 12px 2px #ddd)",
                      }}
                      initial="initial"
                      variants={{
                        initial: { scale: 0.8, transition: { duration: 1 } },
                        hover: { scale: 0.85, transition: { duration: 1 } },
                      }}
                    />
                  </motion.div>
                ))
              ) : (
                <EmptyProductTypes />
              )}
            </Box>
          </Box>
        </Container>

        <Footer />
      </Box>
    </>
  );
}

export default ProductsByCategory;

import styled from "styled-components";
import {motion} from 'framer-motion';

export const StyledFullPageSection = styled(motion.section)`
  margin: 1rem;
  height: 80vh;
  /* margin-top: 8rem; */
  /* min-height:800px; */
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
`;

import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function DressSizeChart() {
  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="dress size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="S" />
                <ChartCell text="M" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="79.5" />
                <ChartCell text="82.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (cm)" />
                <ChartCell text="70" />
                <ChartCell text="74" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Hip (cm)" />
                <ChartCell text="106" />
                <ChartCell text="111" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={14} display={{ xs: "none", sm: "block" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="dress size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="S" />
                <ChartCell text="M" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="79.5" />
                <ChartCell text="82.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (cm)" />
                <ChartCell text="70" />
                <ChartCell text="74" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Hip (cm)" />
                <ChartCell text="106" />
                <ChartCell text="111" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default DressSizeChart;

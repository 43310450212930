import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Container, Typography } from "@material-ui/core";
import AboutImage from "../images/about-blackhill.jpg";

function AboutUs() {
  return (
    <Box>
      <Navbar />
      <Container fixed maxWidth="md" style={{ margin: "5rem auto" }}>
        {/* mobile view */}
        <Box display={{ xs: "block", sm: "none" }}>
          <img
            src={AboutImage}
            alt="about blackhill"
            style={{ width: "100%" }}
          />
          <Typography
            style={{ fontFamily: "El Messiri", marginTop: "2rem" }}
            variant="h5"
          >
            About blackhill
          </Typography>
          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              blackhill is a mountain situated in the eastern part of Hong Kong
              (22°18′43.48″N 114°14′44.36″). Taking the name of this hill; we
              are a brand established in Hong Kong since 2020. We produce
              outdoor fashion and gears meticulously in minimalistic style and
              using only quality materials. We are “blackhill”.
            </Typography>
          </Box>
        </Box>
        {/* web view */}
        <Box
          display={{
            xs: "none",
            sm: "block",
            overflow: "hidden",
            height: "90%",
            width: "100vw",
          }}
        >
          <Typography style={{ fontFamily: "El Messiri" }} variant="h3">
            About blackhill{" "}
          </Typography>

          <div
            style={{
              postion: "fixed",
              margin: 0,
              padding: "5rem",
              paddingLeft: "60vw",
              width: "50vw",
              transform: "translate(-50vw, 30vh)",
              backgroundColor: "rgba(0,0,0,0.95)",
              color: "white",
            }}
          >
            <Typography
              style={{
                fontFamily: "El Messiri",
                marginBottom: "1rem",
              }}
              variant="body1"
              align="justify"
            >
              blackhill is a mountain situated in the eastern part of Hong Kong
              (22°18′43.48″N 114°14′44.36″). Taking the name of this hill; we
              are a brand established in Hong Kong since 2020. We produce
              outdoor fashion and gears meticulously in minimalistic style and
              using only quality materials. We are “blackhill”.
            </Typography>
          </div>
          <img
            src={AboutImage}
            alt="about blackhill"
            style={{
              position: "fixed",
              top: "20%",
              right: "10%",
              width: "70vw",
              height: "60vh",
              objectFit: "cover",
              zIndex: -2,
            }}
          />
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default AboutUs;

import { Box } from "@material-ui/core";
import React from "react";
import MobileSplash from "../components/MobileSplash";
import WebSplash from "../components/WebSplash";

function Splash() {
  return (
    <div>
      {/* web */}
      <Box display={{ xs: "none", sm: "block" }}>
        <WebSplash />
      </Box>

      {/* mobile */}
      <Box display={{ xs: "block", sm: "none" }}>
        <MobileSplash />
      </Box>
    </div>
  );
}

export default Splash;

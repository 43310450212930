import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: #000;
`;

function TermsAndConditions() {
  return (
    <Box>
      <Navbar />
      <Container fixed maxWidth="md" style={{ margin: "5rem auto" }}>
        {/* mobile view */}
        <Box display={{ xs: "block", sm: "none" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h5">
            Terms & Conditions{" "}
          </Typography>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              www.blackhillonline.com, a property of ET Group Productions
              Limited, a body corporate doing business in the Hong Kong Special
              Administrative Region of the People’s Republic of China. This
              document constitutes a legally-binding agreement ("Agreement")
              governing the terms of providing you with our service. Throughout
              this document, the words “blackhill,” “www.blackhillonline.com,"
              “ET Group Productions Limited," "us," "we," and "our," refer to
              us, ET Group Productions Limited, our website,
              www.blakchillonline.com, or our service, BLACKHILL, as is
              appropriate in the context of the use of the words. Likewise, the
              words "you" and "your" refer to you, the person who is being
              presented with this document for your agreement.{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Access to and use of this Site and the products and services
              (collectively, the "Services") are subject to the following terms,
              conditions and notices (collectively, the "Terms of Service" or
              “TOS”). By using the Services, you are agreeing to all of the
              Terms of Service, as may be updated by us from time to time. You
              should check this page regularly to take notice of any changes we
              may have made to the Terms of Service. Access to this Site is
              permitted on a temporary basis, and we reserve the right to
              withdraw or amend the Services without notice. We will not be
              liable if for any reason this Site is unavailable at any time or
              for any period. From time to time, we may restrict access to some
              parts, or all parts of this Site.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              1. Information Supplied
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              When using our website and placing an order, you will provide your
              name, e-mail address, mailing address, PayPal information, credit
              card information and telephone number.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              By registering for our website as a member, you are agreeing to
              receive regular newsletters and marketing related emails from
              BLACKHILL. Should you wish to be removed from our email list, you
              can unsubscribe or terminate your account any time.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              In addition to providing us with the above information about
              yourself, you must be eighteen years of age or older, or, if a
              higher minimum age of contractual capacity exists in your
              jurisdiction, you must be at least that age. Additionally, you
              represent to us that you are not otherwise considered to be under
              a disability to enter into a contract with us, whether it is
              because of an embargo in or against your jurisdiction or any other
              reason.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              2. Intellectual Property, Software and Content{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              The intellectual property rights in all software and content made
              available to you on or through this Site remains the property of
              BLACKHILL or its licensors and are protected by copyright laws and
              treaties around the world. All such rights are reserved by
              BLACKHILL and its licensors. You may store, print, and display the
              content supplied solely for your own personal use. You are not
              permitted to publish, manipulate, distribute, or otherwise
              reproduce, in any format, any of the content or copies of the
              content supplied to you or which appears on the Site nor may you
              use any such content in connection with any business or commercial
              enterprise. You shall not modify, translate, reverse engineer,
              decompile, disassemble or create derivative works based on any
              software or accompanying documentation supplied by BLACKHILL. No
              license or consent is granted to you to use these marks in any
              way, and you agree not to use these marks or any marks which are
              similar in color.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              3. Payment
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              You may pay for our services using Paypal, credit card and Apple
              Pay. When providing credit card information, the credit card must
              be your own. If the credit card does not belong to you, you must
              have permission from the account holder to use their card for your
              transactions.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              We do not accept personal checks, money orders or direct bank
              transfers. Please note, items will not be shipped until the
              transaction has been cleared.{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Any use of fraudulent chargebacks simply to get free merchandise
              is not only strictly prohibited, but also criminal fraud, and will
              be dealt with by reporting such chargebacks to your local police
              department and a credit bureau, as well as referring the matter to
              a court of competent jurisdiction.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              4. Delivery{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Whether you are ordering products for yourself or others, you must
              ensure that you provide us with an accurate address. We cannot be
              held responsible for delivery failures if you don’t give us the
              correct address or if it is an address that we can't perform
              delivery to. If a shipment is returned as unclaimed,
              undeliverable, regardless of the reason, we shall be entitled to
              keep you liable for the shipping and handling fees that were
              associated with that shipment.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              We recommend that items be sent to a correct work or home address
              in order to avoid any delays in receiving your items.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Shipping will be done according to the costs and methods described
              on our website. Once we ship your item, we are no longer
              responsible for its condition or delivery to your chosen address.
              Any claims for damages of the item(s) should be responsible by you
              against the shipper.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Please refer to our{" "}
              <StyledLink to="/shipping-information">
                ‘Shipping Information’
              </StyledLink>{" "}
              page.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              5. Order Confirmation{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              An e-mail confirmation will be sent once payment has been
              received. Once the order has been confirmed, the item(s) will be
              processed, packed and shipped out and as such, it will not be
              possible to cancel your order. In the event that you opt to cancel
              the order after delivery, you agree that the associated delivery
              charges will be deducted from your payment, before making any
              refund by us. If you do not receive your order confirmation email
              in your inbox, please check your Spam folder.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              6. Shipping Confirmation{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Once your order has been dispatched, a shipping confirmation
              e-mail will be sent. Please keep this e-mail as a reference as it
              will also include your order tracking number, carrier and
              instructions on how to track your package.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              7. Exchanges and Returns{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Please refer to our{" "}
              <StyledLink to="/return-policy">
                ‘Exchanges and Returns’{" "}
              </StyledLink>
              page.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              8. Price Changes, Quantity Limitation & Stock Availability{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Prices are subject to change without prior notice.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              While we endeavor to provide you with the most accurate and
              up-to-date information, occasionally ordered items may not be
              available as listed.{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              In the case that part of your order is unavailable or out of
              stock, the item(s) will be cancelled from the order with
              notification and where applicable, a refund shall be applied for
              the cost of the regarding item(s).{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              BLACKHILL ensures that all our product descriptions and prices are
              up-to-date and as accurate as possible. However, in few cases,
              pricing errors may occur with our listings. Should we discover a
              pricing error while processing your order and payment, we will
              immediately rectify the problem before cancelling and refunding
              you for your order. We will also make sure to inform you about the
              error with your order as soon as possible.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              9. Rules of Conduct{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              As a part of using our website, you must not:{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  Hack, crack, phish, SQL inject, or otherwise threaten or
                  actually harm the security of our systems or those of our
                  users.
                </li>
                <li>Infringe on the intellectual property rights of anyone.</li>
                <li>
                  Do anything that infringes on the rights of us or a third
                  party.
                </li>
                <li>
                  Create more than one account, use another person’s account, or
                  allow another person to use your account.
                </li>
                <li>Advertise on our website without our permission.</li>
                <li>Impersonate another person or organization.</li>
              </ul>
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              We reserve the right to terminate our service to you for reasons
              other than those listed here. We may terminate your account
              without explanation.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              10. Disclaimer of Liability{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              The information displayed on the Site is provided without any
              guarantees, conditions or warranties as to its accuracy. BLACKHILL
              does not guarantee that the use of the Site and Services will be
              error-free or the servers are free of other harmful components.
              Though BLACKHILL will do its best to maintain its Services, we
              will not be responsible for failing to meet any obligations in
              this TOS.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              11. Trademarks{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              “BLACKHILL” is a trademark used by us, ET Group Productions
              Limited, to uniquely identify our website, business, and service.
              You agree not to use this phrase anywhere without our prior
              written consent. Additionally, you agree not to use our trade
              dress, or copy the look and feel of our website or its design,
              without our prior written consent. You agree that this paragraph
              goes beyond the governing law on intellectual property law, and
              includes prohibitions on any competition that violates the
              provisions of this paragraph.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              12. Indemnity{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              You agree to indemnify, defend and hold harmless BLACKHILL
              (including its directors, officers, employees, consultants, agents
              and affiliates), from any and all third party claims, liability,
              damages and/or costs (including, but not limited to, legal fees)
              arising from your use this Site or your breach of the TOS.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              13. Waiver{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              If you breach these conditions and we take no action, we will
              still be entitled to use our rights and remedies in any other
              situation where you breach these conditions.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              14. Complaints{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              We have a complaint handling procedure, which we will use to try
              to resolve disputes when they first arise: please contact our
              Customer Service team for any enquiries related to your order and
              complaints.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              15. Governing Law{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              The TOS, together with all our policies and procedures, are
              governed by and construed in accordance with Hong Kong SAR law.
              The relevant courts of Hong Kong SAR will have exclusive
              jurisdiction in the event of any dispute or claim associated with
              these TOS.{" "}
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              16. Contact Information{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              If you have any questions, concerns or notices of violations to
              these terms and conditions, please contact us by sending an email
              to info@blakchillonline.com{" "}
            </Typography>
          </Box>
        </Box>

        {/* web view */}
        <Box display={{ xs: "none", sm: "block" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h3">
            Terms & Conditions{" "}
          </Typography>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              www.blackhillonline.com, a property of ET Group Productions
              Limited, a body corporate doing business in the Hong Kong Special
              Administrative Region of the People’s Republic of China. This
              document constitutes a legally-binding agreement ("Agreement")
              governing the terms of providing you with our service. Throughout
              this document, the words “blackhill,” “www.blackhillonline.com,"
              “ET Group Productions Limited," "us," "we," and "our," refer to
              us, ET Group Productions Limited, our website,
              www.blakchillonline.com, or our service, BLACKHILL, as is
              appropriate in the context of the use of the words. Likewise, the
              words "you" and "your" refer to you, the person who is being
              presented with this document for your agreement.{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Access to and use of this Site and the products and services
              (collectively, the "Services") are subject to the following terms,
              conditions and notices (collectively, the "Terms of Service" or
              “TOS”). By using the Services, you are agreeing to all of the
              Terms of Service, as may be updated by us from time to time. You
              should check this page regularly to take notice of any changes we
              may have made to the Terms of Service. Access to this Site is
              permitted on a temporary basis, and we reserve the right to
              withdraw or amend the Services without notice. We will not be
              liable if for any reason this Site is unavailable at any time or
              for any period. From time to time, we may restrict access to some
              parts, or all parts of this Site.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              1. Information Supplied
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              When using our website and placing an order, you will provide your
              name, e-mail address, mailing address, PayPal information, credit
              card information and telephone number.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              By registering for our website as a member, you are agreeing to
              receive regular newsletters and marketing related emails from
              BLACKHILL. Should you wish to be removed from our email list, you
              can unsubscribe or terminate your account any time.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              In addition to providing us with the above information about
              yourself, you must be eighteen years of age or older, or, if a
              higher minimum age of contractual capacity exists in your
              jurisdiction, you must be at least that age. Additionally, you
              represent to us that you are not otherwise considered to be under
              a disability to enter into a contract with us, whether it is
              because of an embargo in or against your jurisdiction or any other
              reason.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              2. Intellectual Property, Software and Content{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              The intellectual property rights in all software and content made
              available to you on or through this Site remains the property of
              BLACKHILL or its licensors and are protected by copyright laws and
              treaties around the world. All such rights are reserved by
              BLACKHILL and its licensors. You may store, print, and display the
              content supplied solely for your own personal use. You are not
              permitted to publish, manipulate, distribute, or otherwise
              reproduce, in any format, any of the content or copies of the
              content supplied to you or which appears on the Site nor may you
              use any such content in connection with any business or commercial
              enterprise. You shall not modify, translate, reverse engineer,
              decompile, disassemble or create derivative works based on any
              software or accompanying documentation supplied by BLACKHILL. No
              license or consent is granted to you to use these marks in any
              way, and you agree not to use these marks or any marks which are
              similar in color.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              3. Payment
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              You may pay for our services using Paypal, credit card and Apple
              Pay. When providing credit card information, the credit card must
              be your own. If the credit card does not belong to you, you must
              have permission from the account holder to use their card for your
              transactions.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              We do not accept personal checks, money orders or direct bank
              transfers. Please note, items will not be shipped until the
              transaction has been cleared.{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Any use of fraudulent chargebacks simply to get free merchandise
              is not only strictly prohibited, but also criminal fraud, and will
              be dealt with by reporting such chargebacks to your local police
              department and a credit bureau, as well as referring the matter to
              a court of competent jurisdiction.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              4. Delivery{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Whether you are ordering products for yourself or others, you must
              ensure that you provide us with an accurate address. We cannot be
              held responsible for delivery failures if you don’t give us the
              correct address or if it is an address that we can't perform
              delivery to. If a shipment is returned as unclaimed,
              undeliverable, regardless of the reason, we shall be entitled to
              keep you liable for the shipping and handling fees that were
              associated with that shipment.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              We recommend that items be sent to a correct work or home address
              in order to avoid any delays in receiving your items.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Shipping will be done according to the costs and methods described
              on our website. Once we ship your item, we are no longer
              responsible for its condition or delivery to your chosen address.
              Any claims for damages of the item(s) should be responsible by you
              against the shipper.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Please refer to our{" "}
              <StyledLink to="/shipping-information">
                ‘Shipping Information’
              </StyledLink>{" "}
              page.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              5. Order Confirmation{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              An e-mail confirmation will be sent once payment has been
              received. Once the order has been confirmed, the item(s) will be
              processed, packed and shipped out and as such, it will not be
              possible to cancel your order. In the event that you opt to cancel
              the order after delivery, you agree that the associated delivery
              charges will be deducted from your payment, before making any
              refund by us. If you do not receive your order confirmation email
              in your inbox, please check your Spam folder.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              6. Shipping Confirmation{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Once your order has been dispatched, a shipping confirmation
              e-mail will be sent. Please keep this e-mail as a reference as it
              will also include your order tracking number, carrier and
              instructions on how to track your package.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              7. Exchanges and Returns{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Please refer to our{" "}
              <StyledLink to="/return-policy">
                ‘Exchanges and Returns’{" "}
              </StyledLink>
              page.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              8. Price Changes, Quantity Limitation & Stock Availability{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Prices are subject to change without prior notice.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              While we endeavor to provide you with the most accurate and
              up-to-date information, occasionally ordered items may not be
              available as listed.{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              In the case that part of your order is unavailable or out of
              stock, the item(s) will be cancelled from the order with
              notification and where applicable, a refund shall be applied for
              the cost of the regarding item(s).{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              BLACKHILL ensures that all our product descriptions and prices are
              up-to-date and as accurate as possible. However, in few cases,
              pricing errors may occur with our listings. Should we discover a
              pricing error while processing your order and payment, we will
              immediately rectify the problem before cancelling and refunding
              you for your order. We will also make sure to inform you about the
              error with your order as soon as possible.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              9. Rules of Conduct{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              As a part of using our website, you must not:{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  Hack, crack, phish, SQL inject, or otherwise threaten or
                  actually harm the security of our systems or those of our
                  users.
                </li>
                <li>Infringe on the intellectual property rights of anyone.</li>
                <li>
                  Do anything that infringes on the rights of us or a third
                  party.
                </li>
                <li>
                  Create more than one account, use another person’s account, or
                  allow another person to use your account.
                </li>
                <li>Advertise on our website without our permission.</li>
                <li>Impersonate another person or organization.</li>
              </ul>
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              We reserve the right to terminate our service to you for reasons
              other than those listed here. We may terminate your account
              without explanation.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              10. Disclaimer of Liability{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              The information displayed on the Site is provided without any
              guarantees, conditions or warranties as to its accuracy. BLACKHILL
              does not guarantee that the use of the Site and Services will be
              error-free or the servers are free of other harmful components.
              Though BLACKHILL will do its best to maintain its Services, we
              will not be responsible for failing to meet any obligations in
              this TOS.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              11. Trademarks{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              “BLACKHILL” is a trademark used by us, ET Group Productions
              Limited, to uniquely identify our website, business, and service.
              You agree not to use this phrase anywhere without our prior
              written consent. Additionally, you agree not to use our trade
              dress, or copy the look and feel of our website or its design,
              without our prior written consent. You agree that this paragraph
              goes beyond the governing law on intellectual property law, and
              includes prohibitions on any competition that violates the
              provisions of this paragraph.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              12. Indemnity{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              You agree to indemnify, defend and hold harmless BLACKHILL
              (including its directors, officers, employees, consultants, agents
              and affiliates), from any and all third party claims, liability,
              damages and/or costs (including, but not limited to, legal fees)
              arising from your use this Site or your breach of the TOS.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              13. Waiver{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              If you breach these conditions and we take no action, we will
              still be entitled to use our rights and remedies in any other
              situation where you breach these conditions.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              14. Complaints{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              We have a complaint handling procedure, which we will use to try
              to resolve disputes when they first arise: please contact our
              Customer Service team for any enquiries related to your order and
              complaints.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              15. Governing Law{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              The TOS, together with all our policies and procedures, are
              governed by and construed in accordance with Hong Kong SAR law.
              The relevant courts of Hong Kong SAR will have exclusive
              jurisdiction in the event of any dispute or claim associated with
              these TOS.{" "}
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              16. Contact Information{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              If you have any questions, concerns or notices of violations to
              these terms and conditions, please contact us by sending an email
              to info@blakchillonline.com{" "}
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default TermsAndConditions;

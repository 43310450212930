import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function TryMyBestFunctionalShortsSizeChart() {
  const XS = 'XS';
  const S = 'S';
  const M = 'M';
const L = 'L';
const XL = 'XL';

  const XSWaist = '28"-29"';
  const SWaist = '30"-31"';
  const MWaist = '32"-33"';
  const LWaist = '34"-35"';
  const XLWaist = '36"-37"';

  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="try my best functional shorts size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text={XS} />
                <ChartCell text={S} />
                <ChartCell text={M} />
                <ChartCell text={L} />
                <ChartCell text={XL} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="42" />
                <ChartCell text="47" />
                <ChartCell text="48" />
                <ChartCell text="48" />
                <ChartCell text="48" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (inch)" />
                <ChartCell text={XSWaist}/>
                <ChartCell text={SWaist}/>
                <ChartCell text={MWaist} />
                <ChartCell text={LWaist} />
                <ChartCell text={XLWaist} />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Front Rise (cm)" />
                <ChartCell text="27.5" />
                <ChartCell text="28" />
                <ChartCell text="29" />
                <ChartCell text="29" />
                <ChartCell text="30" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Leg Opening (cm)" />
                <ChartCell text="28" />
                <ChartCell text="29" />
                <ChartCell text="30" />
                <ChartCell text="31" />
                <ChartCell text="32" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={8} display={{ xs: "none", sm: "block" }}>
      <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="try my best functional shorts size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text={XS} />
                <ChartCell text={S} />
                <ChartCell text={M} />
                <ChartCell text={L} />
                <ChartCell text={XL} />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="42" />
                <ChartCell text="47" />
                <ChartCell text="48" />
                <ChartCell text="48" />
                <ChartCell text="48" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist (inch)" />
                <ChartCell text={XSWaist}/>
                <ChartCell text={SWaist}/>
                <ChartCell text={MWaist} />
                <ChartCell text={LWaist} />
                <ChartCell text={XLWaist} />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Front Rise (cm)" />
                <ChartCell text="27.5" />
                <ChartCell text="28" />
                <ChartCell text="29" />
                <ChartCell text="29" />
                <ChartCell text="30" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Leg Opening (cm)" />
                <ChartCell text="28" />
                <ChartCell text="29" />
                <ChartCell text="30" />
                <ChartCell text="31" />
                <ChartCell text="32" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default TryMyBestFunctionalShortsSizeChart;

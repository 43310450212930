import React from "react";
import { Box, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { sections } from '../data/data';

const StyledLink = styled(Link)`
  /* display: inline-block; */
  color: black;
  text-decoration: none;
  /* width: 12vw; */
`;
export default function CategorySelector() {
  return (
    <div>
      <Container style={{ margin: "5rem auto" }}>
        <Box
          m={1}
          style={{
            margin: "0 auto",
            padding: "1rem",
            borderTop: "1px solid lightgray",
            borderBottom: "1px solid lightgray",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {sections && sections.map(s => (
            <StyledLink key={s.id} to={s.link}>
            <Typography variant="subtitle1" >{s.name}</Typography>
          </StyledLink>
          ))}
          
        </Box>
      </Container>
    </div>
  );
}

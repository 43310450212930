import React from "react";
import { AppBar, Typography } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
`;
function Footer() {
  return (
    <div>
      <AppBar
        position="fixed"
        color="primary"
        style={{ top: "auto", bottom: 0, padding: "0.5rem 1rem " }}
      >
        <Typography variant="caption">
          <StyledLink to="/shipping-information">
            Shipping Information
          </StyledLink>
          {" | "}
          <StyledLink to="/terms-n-conditions">Terms & Conditions</StyledLink>
          {" | "}
          <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
          {" | "}
          <StyledLink to="/return-policy">Exchange & Return</StyledLink>
          {" | "}
          <StyledLink to="/about-us">About blackhill</StyledLink>
          {/* <StyledLink to="/shipping-information">
            Shipping
          </StyledLink>
          {" | "}
          <StyledLink to="/terms-n-conditions">T&C</StyledLink>
          {" | "}
          <StyledLink to="/privacy-policy">Privacy</StyledLink>
          {" | "}
          <StyledLink to="/return-policy">Exchange</StyledLink>
          {" | "}
          <StyledLink to="/about">About</StyledLink> */}
        </Typography>
      </AppBar>
    </div>
  );
}

export default Footer;

import { Box, Typography } from "@material-ui/core";

function HikeExitProtectOurMountainsHikingTeeDescription() {
  return (
    <>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          盛夏過去代表行山露營季節隨即開始，上一年香港人與山過了非常瘋狂的一年，山上人頭湧湧大家都希望遠離城市尋求出口，令山野面對住前所未有嘅負荷，山火焚燒、垃圾污染、開墾山徑及登山意外等問題同時接踵而來。
        </Typography>
      </Box>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          Protect Our Mountain
        </Typography>
      </Box>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          今年踏入無法外遊的兩週年，大自然繼續成為我們嘅唯一出口，經過上一年的洗禮，山野無痕及行山禮儀等訊息都成功傳遞到不少山友嘅心中，今年希望大家能夠將過去嘅經驗分享俾新手山友，一齊建立守護山野嘅文化，令人潮與大自然能夠並存。{" "}
        </Typography>
      </Box>
    </>
  );
}

export default HikeExitProtectOurMountainsHikingTeeDescription;

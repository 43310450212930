import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function TryMyBestSizeChart() {
  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="try my best size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size (Slim Fit)" />
                <ChartCell text="XXS" />
                <ChartCell text="XS" />
                <ChartCell text="S" />
                <ChartCell text="M" />
                <ChartCell text="L" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Half Chest (cm)" />
                <ChartCell text="46" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="51" />
                <ChartCell text="54" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Bottom Width (cm)" />
                <ChartCell text="46" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="51" />
                <ChartCell text="54" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Shoulder (cm)" />
                <ChartCell text="40" />
                <ChartCell text="41.5" />
                <ChartCell text="45" />
                <ChartCell text="45.5" />
                <ChartCell text="46.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="54.5" />
                <ChartCell text="58" />
                <ChartCell text="62.5" />
                <ChartCell text="65" />
                <ChartCell text="68" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={8} display={{ xs: "none", sm: "block" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="try my best size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size (Slim Fit)" />
                <ChartCell text="XXS" />
                <ChartCell text="XS" />
                <ChartCell text="S" />
                <ChartCell text="M" />
                <ChartCell text="L" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Half Chest (cm)" />
                <ChartCell text="46" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="51" />
                <ChartCell text="54" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Bottom Width (cm)" />
                <ChartCell text="46" />
                <ChartCell text="48" />
                <ChartCell text="50" />
                <ChartCell text="51" />
                <ChartCell text="54" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Shoulder (cm)" />
                <ChartCell text="40" />
                <ChartCell text="41.5" />
                <ChartCell text="45" />
                <ChartCell text="45.5" />
                <ChartCell text="46.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="54.5" />
                <ChartCell text="58" />
                <ChartCell text="62.5" />
                <ChartCell text="65" />
                <ChartCell text="68" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default TryMyBestSizeChart;

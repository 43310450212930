import React from "react";

function EmptyProductTypes() {
  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
       <div
         style={{
           fontFamily: "Manrope",
           fontSize: '1rem',
           fontWeight: 'bolder',
           position: "fixed",
           top: "50%",
           left: "50%",
           transform: "translate(-50%, -50%)",
         }}
       >
         <p>More collection is coming</p>
       </div>
     </div>
  );
}

export default EmptyProductTypes;

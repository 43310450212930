import React from "react";
import { Box, Container } from "@material-ui/core";

// api
import { useQuery } from "@apollo/client";
import { GetAllProductsGql } from "../api/graphql";

// components
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import CategorySelector from "../components/CategorySelector";
import Loading from "../components/Loading";
import EmptyProductTypes from "../components/EmptyProductTypes";
import WebProductGridBox from "../components/WebProductGridBox";
import MobileProductGridBox from "../components/MobileProductGridBox";

function Products() {
  // obtained data
  const { loading, error, data } = useQuery(GetAllProductsGql);

  if (loading) return <Loading />;
  if (error) return <p>Error: {error.message}</p>;

  const orderedProducts = [...data.products.edges].reverse();

  return (
    <Box>
      <Navbar />

      <Box display={{ xs: "none", sm: "block" }}>
        <CategorySelector />
      </Box>

      <Container maxWidth="lg" style={{ margin: "5rem auto" }}>
        {/* web view */}
        <Box display={{ xs: "none", sm: "block" }}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
          >
            {orderedProducts ? (
              orderedProducts.map((product, i) => (
                <WebProductGridBox key={"web_" + i} product={product} />
              ))
            ) : (
              <EmptyProductTypes />
            )}
          </Box>
        </Box>

        {/* mobile view */}
        <Box
          display={{
            xs: "block",
            sm: "none",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {orderedProducts ? (
              orderedProducts.map((product, i) => (
                <MobileProductGridBox key={"mobile_" + i} product={product} />
              ))
            ) : (
              <EmptyProductTypes />
            )}
          </Box>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
}

export default Products;

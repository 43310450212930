import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: #000;
`;

function ShippingInformation() {
  return (
    <Box>
      <Navbar />
      <Container fixed maxWidth="md" style={{ margin: "5rem auto" }}>
        {/* mobile view */}
        <Box display={{ xs: "block", sm: "none" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h5">
            Shipping Information
          </Typography>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              delivery
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              our products ship worldwide.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              China, Hong Kong Special Administrative Region and Macau Special
              Administrative Region orders will be shipped using{" "}
              <StyledLink
                to={{ pathname: "https://htm.sf-express.com/" }}
                target="_blank"
              >
                S.F. Express
              </StyledLink>
              delivery service. all other orders will be shipped using Hong Kong
              Post - SpeedPost delivery service.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  all orders which included accessories will only be shipped by
                  using{" "}
                  <StyledLink
                    to={{ pathname: "https://www.hongkongpost.hk/" }}
                    target="_blank"
                  >
                    Hong Kong Post
                  </StyledLink>{" "}
                  - SpeedPost delivery service.
                </li>
                <li>
                  all shipment does not deliver to P.O. Boxes, General Delivery
                  and Poste Restante.
                </li>
              </ul>
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              delivery regions
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              our products ship internationally to most of the area, includes
              Australia, Belgium, Canada, Mainland China, Denmark, France,
              Germany, Hong Kong Special Administrative Region, Indonesia,
              Ireland, Italy, Japan, Macau Special Administrative Region,
              Malaysia, New Zealand, Philippines, Singapore, South Africa, South
              Korea, Spain, Sweden, Switzerland, Taiwan, United States, United
              Kingdom and Vietnam.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              for details, please refer to the area list when you check out. if
              your area does not appear in the list, unfortunately we do not
              deliver to where you live at the moment. we will add new delivery
              destinations all the times so please keep updated on our website.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              *tracking numbers are provided for all international and local
              orders.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              once your order is dispatched, you will receive an email
              notification from us with the tracking number. please check the
              delivery status via the{" "}
              <StyledLink
                to={{ pathname: "https://www.hongkongpost.hk/" }}
                target="_blank"
              >
                Hong Kong Post
              </StyledLink>{" "}
              and{" "}
              <StyledLink
                to={{ pathname: "https://htm.sf-express.com/" }}
                target="_blank"
              >
                S.F. Express
              </StyledLink>{" "}
              website with tracking number.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              shipping cost
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              all shipping costs (with administration fee included) will be
              according to the weight of the products during the check out
              procedures. please check your order summary thoroughly before you
              complete the payment process.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              delivery times
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              orders will normally be processed within 5 business days once your
              payment is completed and approved.
            </Typography>
            <table>
              <tr>
                <th>shipping region</th>
                <th>standard delivery times in Business Days (Mon-Fri)</th>
              </tr>
              <tr>
                <td>Hong Kong Special Administrative Region</td>
                <td>estimated up to 3 business days</td>
              </tr>
              <tr>
                <td>China</td>
                <td>estimated up to 3-14 business days</td>
              </tr>
              <tr>
                <td>Taiwan & Macau Special Administrative Region</td>
                <td>estimated up to 6 business days</td>
              </tr>
              <tr>
                <td>Asia Pacific (including Australia)</td>
                <td>estimated up to 7 business days</td>
              </tr>
              <tr>
                <td>North America (US & Canada)</td>
                <td>estimated up to 7 business days</td>
              </tr>
              <tr>
                <td>Europe</td>
                <td>estimated up to 7 business days</td>
              </tr>
              <tr>
                <td>Others</td>
                <td>estimated up to 14 business days</td>
              </tr>
            </table>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              * delivery times are determined by number of business days (Monday
              to Friday) from the day after we ship your order.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              *please allow extra time for your order to be processed during
              public holidays and busy periods. delays may occur as a result of
              pending payment authorisations.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              *if you do not receive a tracking confirmation within 3-7 business
              days from the day you completed your payment, please kindly
              <StyledLink to="/contact-us">contact us</StyledLink> for more
              information.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              *please be informed that in some cases your local customs office
              may require additional documents and time to have your package
              cleared. during such instances, the estimated delivery time may be
              affected.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              shipping address and failed deliveries
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we can only change your shipping address in the case orders are
              still with us. for those already shipped, an additional fee (which
              we call it as “re-routing fee”) will be charged for the request.
              please take this into consideration when selecting and filling in
              your shipping address for order.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              for failed deliveries (courier is unable to reach customer due to
              the incorrect address provide by customer, failed attempts to
              reach customer at the location or customer refuse to pay duties),
              we are not responsible for the returned shipping cost. for
              re-shipped orders, the shipping costs are also payable by
              customers. if you refuse to receive the order and ask for a
              refund, the order amount will not be refunded in any
              circumstances.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              import taxes and duties
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              customs duties, including Goods and Services Tax (GST),
              Value-added Tax (VAT), are not calculated in the price of the
              product.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              please be advised that import taxes, duties and related customs
              fees may be charged once the parcel arrives at the destination
              area. these charges are determined by the customs office of the
              regarding destination.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              payment of these charges and taxes is the responsibility of the
              recipient and will not be covered by us. for further details of
              charges, please contact your local customs office. in some
              occasions packages may be delayed due to custom clearance.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              customs and regulations
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              customers should check local postal regulations for restricted
              items, as we cannot be held liable for any items that are not
              accepted into the regarding regions.
            </Typography>
          </Box>
        </Box>

        {/* web view */}
        <Box display={{ xs: "none", sm: "block" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h3">
            Shipping Information
          </Typography>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              delivery
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              our products ship worldwide.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              China, Hong Kong Special Administrative Region and Macau Special
              Administrative Region orders will be shipped using{" "}
              <StyledLink
                to={{ pathname: "https://htm.sf-express.com/" }}
                target="_blank"
              >
                S.F. Express
              </StyledLink>{" "}
              delivery service. all other orders will be shipped using Hong Kong
              Post - SpeedPost delivery service.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  all orders which included accessories will only be shipped by
                  using{" "}
                  <StyledLink
                    to={{ pathname: "https://www.hongkongpost.hk/" }}
                    target="_blank"
                  >
                    Hong Kong Post
                  </StyledLink>{" "}
                  - SpeedPost delivery service.
                </li>
                <li>
                  all shipment does not deliver to P.O. Boxes, General Delivery
                  and Poste Restante.
                </li>
              </ul>
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              delivery regions
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              our products ship internationally to most of the area, includes
              Australia, Belgium, Canada, Mainland China, Denmark, France,
              Germany, Hong Kong Special Administrative Region, Indonesia,
              Ireland, Italy, Japan, Macau Special Administrative Region,
              Malaysia, New Zealand, Philippines, Singapore, South Africa, South
              Korea, Spain, Sweden, Switzerland, Taiwan, United States, United
              Kingdom and Vietnam.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              for details, please refer to the area list when you check out. if
              your area does not appear in the list, unfortunately we do not
              deliver to where you live at the moment. we will add new delivery
              destinations all the times so please keep updated on our website.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              *tracking numbers are provided for all international and local
              orders.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              once your order is dispatched, you will receive an email
              notification from us with the tracking number. please check the
              delivery status via the{" "}
              <StyledLink
                to={{ pathname: "https://www.hongkongpost.hk/" }}
                target="_blank"
              >
                Hong Kong Post
              </StyledLink>{" "}
              and{" "}
              <StyledLink
                to={{ pathname: "https://htm.sf-express.com/" }}
                target="_blank"
              >
                S.F. Express
              </StyledLink>{" "}
              website with tracking number.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              shipping cost
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              all shipping costs (with administration fee included) will be
              according to the weight of the products during the check out
              procedures. please check your order summary thoroughly before you
              complete the payment process.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              delivery times
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              orders will normally be processed within 5 business days once your
              payment is completed and approved.
            </Typography>
            <table>
              <tr>
                <th>shipping region</th>
                <th>standard delivery times in Business Days (Mon-Fri)</th>
              </tr>
              <tr>
                <td>Hong Kong Special Administrative Region</td>
                <td>estimated up to 3 business days</td>
              </tr>
              <tr>
                <td>China</td>
                <td>estimated up to 3-14 business days</td>
              </tr>
              <tr>
                <td>Taiwan & Macau Special Administrative Region</td>
                <td>estimated up to 6 business days</td>
              </tr>
              <tr>
                <td>Asia Pacific (including Australia)</td>
                <td>estimated up to 7 business days</td>
              </tr>
              <tr>
                <td>North America (US & Canada)</td>
                <td>estimated up to 7 business days</td>
              </tr>
              <tr>
                <td>Europe</td>
                <td>estimated up to 7 business days</td>
              </tr>
              <tr>
                <td>Others</td>
                <td>estimated up to 14 business days</td>
              </tr>
            </table>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              * delivery times are determined by number of business days (Monday
              to Friday) from the day after we ship your order.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              *please allow extra time for your order to be processed during
              public holidays and busy periods. delays may occur as a result of
              pending payment authorisations.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              *if you do not receive a tracking confirmation within 3-7 business
              days from the day you completed your payment, please kindly{" "}
              <StyledLink to="/contact-us">contact us</StyledLink> for more
              information.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              *please be informed that in some cases your local customs office
              may require additional documents and time to have your package
              cleared. during such instances, the estimated delivery time may be
              affected.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              shipping address and failed deliveries
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we can only change your shipping address in the case orders are
              still with us. for those already shipped, an additional fee (which
              we call it as “re-routing fee”) will be charged for the request.
              please take this into consideration when selecting and filling in
              your shipping address for order.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              for failed deliveries (courier is unable to reach customer due to
              the incorrect address provide by customer, failed attempts to
              reach customer at the location or customer refuse to pay duties),
              we are not responsible for the returned shipping cost. for
              re-shipped orders, the shipping costs are also payable by
              customers. if you refuse to receive the order and ask for a
              refund, the order amount will not be refunded in any
              circumstances.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              import taxes and duties
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              customs duties, including Goods and Services Tax (GST),
              Value-added Tax (VAT), are not calculated in the price of the
              product.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              please be advised that import taxes, duties and related customs
              fees may be charged once the parcel arrives at the destination
              area. these charges are determined by the customs office of the
              regarding destination.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              payment of these charges and taxes is the responsibility of the
              recipient and will not be covered by us. for further details of
              charges, please contact your local customs office. in some
              occasions packages may be delayed due to custom clearance.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              customs and regulations
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              customers should check local postal regulations for restricted
              items, as we cannot be held liable for any items that are not
              accepted into the regarding regions.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default ShippingInformation;

import { TableCell } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

export const LeftChartCell = ({ text }) => (
  <StyledTableCell
    align="left"
    style={{
      padding: 2,
      paddingLeft: "1rem",
      fontSize: "0.8rem",
    }}
  >
    {text}
  </StyledTableCell>
);

export const ChartCell = ({ text }) => (
  <StyledTableCell
    align="center"
    style={{
      padding: 2,
      fontSize: "0.8rem",
    }}
  >
    {text}
  </StyledTableCell>
);

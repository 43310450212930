import React from "react";
import {
  TableContainer,
  TableRow,
  Table,
  TableHead,
  TableBody,
  Paper,
  Box,
} from "@material-ui/core";
import { LeftChartCell, ChartCell } from "../../styles/charts";

function RunningShortsSizeChart() {
  return (
    <>
      {/* mobile view */}
      <Box m={2} display={{ xs: "block", sm: "none" }}>
        <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="running shorts size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="XS" />
                <ChartCell text="S" />
                <ChartCell text="M" />
                <ChartCell text="L" />
                <ChartCell text="XL" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="30" />
                <ChartCell text="31.5" />
                <ChartCell text="33" />
                <ChartCell text="34.5" />
                <ChartCell text="36" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist - Half (cm)" />
                <ChartCell text="30" />
                <ChartCell text="32.5" />
                <ChartCell text="35" />
                <ChartCell text="37.5" />
                <ChartCell text="40" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Front Rise (cm)" />
                <ChartCell text="25.5" />
                <ChartCell text="26.5" />
                <ChartCell text="27.5" />
                <ChartCell text="28.5" />
                <ChartCell text="29.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Leg Opening - Half (cm)" />
                <ChartCell text="29" />
                <ChartCell text="30" />
                <ChartCell text="31" />
                <ChartCell text="32" />
                <ChartCell text="33" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* web view */}
      <Box m={14} display={{ xs: "none", sm: "block" }}>
      <TableContainer component={Paper} elevation={0}>
          <Table size="small" aria-label="running shorts size chart">
            <TableHead>
              <TableRow>
                <LeftChartCell text="Size" />
                <ChartCell text="XS" />
                <ChartCell text="S" />
                <ChartCell text="M" />
                <ChartCell text="L" />
                <ChartCell text="XL" />
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <LeftChartCell text="Length (cm)" />
                <ChartCell text="30" />
                <ChartCell text="31.5" />
                <ChartCell text="33" />
                <ChartCell text="34.5" />
                <ChartCell text="36" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Waist - Half (cm)" />
                <ChartCell text="30" />
                <ChartCell text="32.5" />
                <ChartCell text="35" />
                <ChartCell text="37.5" />
                <ChartCell text="40" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Front Rise (cm)" />
                <ChartCell text="25.5" />
                <ChartCell text="26.5" />
                <ChartCell text="27.5" />
                <ChartCell text="28.5" />
                <ChartCell text="29.5" />
              </TableRow>
              <TableRow>
                <LeftChartCell text="Leg Opening - Half (cm)" />
                <ChartCell text="29" />
                <ChartCell text="30" />
                <ChartCell text="31" />
                <ChartCell text="32" />
                <ChartCell text="33" />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default RunningShortsSizeChart;

import { gql } from "@apollo/client";

export const GetProductsByTypeGql = (productType) => gql`
query GetProductsByType {
  products(first: 100, query: "product_type:${productType}") {
    edges {
      node {
        id
        title
        description
        productType
      
        images(first: 20) {
          edges {
            node {
              id
              src
              originalSrc
              height
              altText
            }
          }
        }
        variants(first: 10) {
          edges {
            node {
              id
              availableForSale
              quantityAvailable
            }
          }
        }
      }
    }
  }
}
`;

export const GetAllProductsGql = gql`
  query GetProducts {
    products(first: 100) {
      edges {
        node {
          id
          title
          description
          productType

          images(first: 20) {
            edges {
              node {
                id
                src
                originalSrc
                altText
                height
              }
            }
          }

          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const OldGetAllProductsGql = gql`
  query GetProducts {
    products(first: 100) {
      edges {
        node {
          id
          title
          description
          productType

          images(first: 20) {
            edges {
              node {
                id
                src
                originalSrc
                altText
                height
              }
            }
          }
          metafields(first: 10) {
            edges {
              node {
                id
                key
                namespace
              }
            }
          }
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
        }
      }
    }
  }
`;

export const GetProductGql = gql`
  query ProductDetails($productId: ID!) {
    node(id: $productId) {
      id
      ... on Product {
        title
        description
        productType

        images(first: 10) {
          edges {
            node {
              id
              src
            }
          }
        }

        variants(first: 10) {
          edges {
            node {
              id
              title
              availableForSale
              quantityAvailable
              unitPrice {
                amount
                currencyCode
              }

              price {
                amount
                currencyCode
              }

              sku
              selectedOptions {
                name
                value
              }
            }
          }
        }
        onlineStoreUrl
        options(first: 10) {
          id
          name
          values
        }
      }
    }
  }
`;

export const OldGetProductGql = gql`
  query ProductDetails($productId: ID!) {
    node(id: $productId) {
      id
      ... on Product {
        title
        description
        productType

        images(first: 10) {
          edges {
            node {
              id
              src
            }
          }
        }
        variants(first: 10) {
          edges {
            node {
              id
              title
              availableForSale
              quantityAvailable
              unitPrice {
                amount
                currencyCode
              }
              price
              priceV2 {
                amount
                currencyCode
              }
              metafields(first: 5) {
                edges {
                  node {
                    id
                    description
                    value
                    key
                  }
                }
              }
              sku
              selectedOptions {
                name
                value
              }
            }
          }
        }
        onlineStoreUrl
        options(first: 10) {
          id
          name
          values
        }
      }
    }
  }
`;

/**
 * CheckoutLineItemInput
 * customAttributes ([AttributeInput!])
 * quantity (Int!) REQUIRED
 * variantId (ID!) REQUIRED
 */
export const CreateCheckout = gql`
  mutation Checkout($variantId: ID!, $quantity: Int!) {
    checkoutCreate(
      input: { lineItems: [{ variantId: $variantId, quantity: $quantity }] }
    ) {
      checkout {
        id
        webUrl
        lineItems(first: 100) {
          edges {
            node {
              title
              quantity
            }
          }
        }
      }
    }
  }
`;

/**
 * CheckoutCreateInput
 *  {
 *    lineItems: [
 *      {
 *         quantity,
 *         variantId
 *      },...
 *    ]
 *  }
 */
export const CheckoutCreate = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkout {
        id
        webUrl
        lineItems(first: 100) {
          edges {
            node {
              title
              quantity
            }
          }
        }
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CheckoutEmailUpdateGql = gql`
  mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
    checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CheckoutShippingAddressUpdateGql = gql`
  mutation checkoutShippingAddressUpdateV2(
    $shippingAddress: MailingAddressInput!
    $checkoutId: ID!
  ) {
    checkoutShippingAddressUpdateV2(
      shippingAddress: $shippingAddress
      checkoutId: $checkoutId
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

// {
//   "lineItems": [
//     {
//       "quantity": 1,
//       "variantId": "Z2lkOi8vU2hvcGlmeS9FeGFtcGxlLzE="
//     }
//   ],
//   "checkoutId": "Z2lkOi8vU2hvcGlmeS9FeGFtcGxlLzE="
// }

export const CheckoutLineItemsAddGql = gql`
  mutation checkoutLineItemsAdd(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

// {
//   {
//   "checkoutId": "Z2lkOi8vU2hvcGlmeS9FeGFtcGxlLzE=",
//   "lineItemIds": [
//     "Z2lkOi8vU2hvcGlmeS9FeGFtcGxlLzE="
//   ]
// }

export const CheckoutLineItemsRemoveGql = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

// {
//   "checkoutId": "Z2lkOi8vU2hvcGlmeS9FeGFtcGxlLzE=",
//   "lineItems": [
//     {}
//   ]
// }

export const CheckoutLineItemsUpdateGql = gql`
  mutation checkoutLineItemsUpdate(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemUpdateInput!]!
  ) {
    checkoutLineItemsUpdate(checkoutId: $checkoutId, lineItems: $lineItems) {
      checkout {
        id
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
`;

export const CheckoutLineItemsReplace = gql`
  mutation {
    checkoutLineItemsReplace(
      lineItems: [
        { variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8z", quantity: 1 }
        { variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC80", quantity: 1 }
      ]
      checkoutId: "Z2lkOi8vc2hvcGlmeS9DaGVja291dC81ZDliYTZjOTlhNWY4YTVhNTFiYzllMzlmODEwNTNhYz9rZXk9NWIxZTg5NDQzNTZkMjMxOGU1N2ZlNjQwZDJiNjY1M2Y="
    ) {
      checkout {
        id
        lineItems(first: 2) {
          edges {
            node {
              id
              title
              quantity
            }
          }
        }
      }
    }
  }
`;

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { FirebaseAppProvider } from "reactfire";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
} from "@material-ui/core";

const client = new ApolloClient({
  uri: "https://blackhill-online.myshopify.com/api/2023-01/graphql.json",
  cache: new InMemoryCache(),
  headers: {
    "X-Shopify-Storefront-Access-Token": "d9b87f3bc08f940f78e118e651127d1a", // blackhill-online
    "Content-Type": "application/graphql",
  },
});

const firebaseConfig = {
  apiKey: "AIzaSyDPXX80KwwETcuizkYljEbY56E2MsNHo4E",
  authDomain: "blackhill-online.firebaseapp.com",
  projectId: "blackhill-online",
  storageBucket: "blackhill-online.appspot.com",
  messagingSenderId: "153935250865",
  appId: "1:153935250865:web:ae3d61b02df570a95ee990",
  measurementId: "G-8PRL6VXGL6",
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#ffcc80",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
          <App />
        </FirebaseAppProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Box, Typography } from "@material-ui/core";

function TryMyBestILoveRunningTeeDescription() {
  return (
    <>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          「店長」- 香港創作品牌4A Like
          Black之店長，一位年過60，熱愛跑步的大叔，是”世界六大馬拉松”完成者Six
          Star Finisher，亦經常出戰香港及海外的大型越野賽事。
        </Typography>
      </Box>
      <Box m={2} style={{ marginBottom: "1rem" }}>
        <Typography variant="body2" align="left">
          「I Love
          Running」簡單既幾隻字就表達咗店長對跑步有幾咁熱愛，所以t-shirt前面印有「I
          Love Running」既簡單子句；衫腳上亦都印有店長座右銘「Try My Best，God
          Will Do The Rest」
        </Typography>
      </Box>
    </>
  );
}

export default TryMyBestILoveRunningTeeDescription;

import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import logo from "../images/logo.png";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { sections } from "../data/data";

const variants = {
  enter: {
    opacity: 0,
    transition: { duration: 2 },
  },
  center: {
    zIndex: 0,
    opacity: 1,
    transition: { duration: 2 },
  },
  exit: {
    zIndex: 0,
    opacity: 0,
    transition: { duration: 2 },
  },
};

const list = {
  visible: {
    opacity: 1,
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.2,
    },
  },
  hidden: {
    opacity: 0,
    // transition: {
    //   when: "afterChildren",
    // },
  },
};

const listItem = {
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.4 },
  },
  hidden: {
    opacity: 0,
    y: -20,
  },
};

const StyledLiVariants = {
  hover: {
    x: 10,
    transition: { duration: 0.3 },
  },
};

const StyledImg = styled(motion.img)`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`;

const StyledUl = styled(motion.ul)`
  margin: 0;
  padding: 0;
  list-style: none;
  color: white;
`;

const StyledLi = styled(motion.li)`
  width: 100%;
  margin: 1rem auto;
  height: 2.4rem;
  text-align: center;
  font-family: "Manrope";
  font-weight: 300;
  font-size: 1.4rem;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: white;
`;

function MobileCategorySelection() {

  const [imageIndex, setImageIndex] = useState(0);
  useEffect(() => {
    console.log("in useEffect, image index", imageIndex);
    const intervalId = setInterval(() => {
      setImageIndex((imageIndex) => (imageIndex + 1) % sections.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <AnimatePresence
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 1 } }}
    >
      <StyledImg
        key={imageIndex}
        variants={variants}    
        initial={{ opacity: 0, zIndex: 0 }}
        animate={{ opacity: 1, zIndex: 1, transition: { duration: 1 } }}
        exit={{ opacity: 0, zIndex: 0, transition: { duration: 5 } }}
        src={sections[imageIndex].imageUrl}
        alt="background"
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50vw",
          zIndex: 10,
        }}
      >
        <img
          style={{
            width: "40vw",
            margin: "2rem auto",
            filter: "invert(1)",
            opacity: 0.6,
          }}
          src={logo}
          alt="logo"
        />
        <StyledUl variants={list} initial="hidden" animate="visible">
          {sections.map((section) => (
            <StyledLi
              key={section.id}
              variants={listItem}
              style={{ fontSize: '1.2rem'}}
            >
              <StyledLink to={section.link}>{section.name}</StyledLink>
            </StyledLi>
          ))}
        </StyledUl>
      </div>
    </AnimatePresence>
  );
}

export default MobileCategorySelection;

import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: #000;
`;

function PrivacyPolicy() {
  return (
    <Box>
      <Navbar />
      <Container fixed maxWidth="md" style={{ margin: "5rem auto" }}>
        {/* mobile view */}
        <Box display={{ xs: "block", sm: "none" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h5">
            Privacy Policy
          </Typography>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              YOUR PERSONAL DATA AND HOW WE USE IT
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we collect, use and store your personal data to ensure we can
              provide various services to you and offer you a personal
              experience online and in our stores. this may occur on our Site or
              via other means. we will keep your personal data confidential at
              all times and only collect, use and store in accordance with this
              Privacy Policy.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              COLLECTION OF YOUR PERSONAL DATA
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we collect the different levels of your personal data, both on our
              site and at our physical stores:
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  you will be browsing our site as a guest if you are not a
                  registered user. we will not collect or use any personal data
                  from you, but you may not be able to use all the services and
                  features that we offer to registered users.
                </li>
                <li>
                  you will need to input certain of your personal data when
                  registering with us on our site (such as your name and contact
                  details), in order to receive various features and services
                  that we offer to our registered users, such as email updates,
                  responses to your queries, and priority for our limited
                  edition items.
                </li>
                <li>
                  you can only place an order with us as a registered user on
                  our site. in both cases, we will only collect information
                  necessary to fullfill the relevant order (such as your name,
                  contact details and payment/credit card information).
                </li>
                <li>
                  you may wish to verify or update any of your personal data
                  that we hold. you may do so by{" "}
                  <StyledLink to="/contact-us">
                    contacting our Customer Service
                  </StyledLink>
                  , or amending your customer profile in your registered
                  account.
                </li>
              </ul>
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              USE OF YOUR PERSONAL DATA
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we use the personal data you provide to us for the following
              purposes:
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  to provide our services to you. This includes responding any
                  of your enquiries, providing you with recommendations on
                  products on our Site, maintaining your shopping cart on our
                  site, assessing your applications with us for any of our
                  services, and fulfilling orders you may make with us
                  (including the security checks of your payments and
                  verification).
                </li>
                <li>
                  to provide you with marketing communications, as we may
                  occasionally update you our latest products, news via e-mail,
                  post and telephone.
                </li>
              </ul>
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              COOKIES
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              please note that we may automatically collect data from you
              relating to your online session to provide our services to you.
              our site may use cookies, which is a small information file that
              is sent to your computer or other device when you visit our site
              and it will recoganize your device on future visits, for example,
              remembering your items in shopping cart. If you do not want
              cookies to be stored on your computer, you can change the settings
              on your browser. The “help” section on your browser will provide
              details on how to manage the cookie settings.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              MARKETING COMMUNICATIONS
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              you agree that we may use your personal data to offer you
              marketing communications from time to time. we may provide you our
              goods and services, and the goods and services of our third party
              providers. this may be presented in various forms, including
              newsletters, advertisements and special promotional events, and
              delivered by various methods based on the personal information you
              provide to us, such as by email, phone notifications or postal
              mail.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              you have the options to opt-out of receiving marketing
              communications from us. if you do not wish to continue to receive
              it, you may click on the “unsubscribe” link in any email
              communications which we might send you, or{" "}
              <StyledLink to="/contact-us">contact our Customer Service</StyledLink>{" "}
              so that we could amend your personal data for you.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              PROTECTING YOUR SECURITY
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we do our best to protect the personal data that you provide to
              us. please be aware that despite our efforts, no data security
              measures can guarantee 100% security at all times.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              when you make online purchase, we protect the personal data that
              you provide to us. we will encrypt any personal data you transmit
              to us via our site using Secure Socket Layer (SSL) – an industry
              standard for encryption over the Internet.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we may use your personal information in order to conduct
              appropriate anti-fraud checks. this may be disclosed to a credit
              reference agency, which may keep a record of the information. this
              is only for confirming your identity, a credit check is not
              performed and your credit rating is unaffected.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Nothing in this Privacy Policy shall limit any of your rights to
              the extent such rights cannot be limited by law.
            </Typography>{" "}
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              DISCLOSURES OF YOUR INFORMATION
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              your personal data will be kept confidential with us, except the
              following parties that we may disclose your personal data to them:
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  any financial institutions or credit card issuing companies,
                  or collection agencies where necessary to establish and
                  support the payment being made by you
                </li>
                <li>
                  any parties where such disclosure is mandated by statutory or
                  contractual obligations or as required by law
                </li>
              </ul>
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              this Privacy Policy is not applicable to any websites maintained
              by third parties. please read such third party’s privacy policy
              before providing your personal data to such third parties (whether
              via our Site or not). we are not responsible and liable for any
              use or breach of your personal data by such third parties.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              HOW TO CHECK AND UPDATE YOUR DETAILS
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you wish to verify the personal data you provide to us, you may
              do so by logging in to your account to update your personal data,
              or contacting our Customer Service by{" "}
              <StyledLink to="/contact-us">Contact Us</StyledLink>. when you contact
              us, our security procedures mean that we may request proof of
              identity before we reveal information. this proof will take the
              form of your email address and password submitted upon
              registration. You must keep this information safe, as you will be
              responsible for any action, which we will take in response to a
              request from someone using your email and password. we strongly
              recommend that you do not use the browser’s password memory
              function, as that would permit others using your terminal to
              access your personal information.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              CONTACTING US
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              If you have any questions or feedback about our Privacy Policy,
              please do not hesitate to Contact Us.
            </Typography>
          </Box>
        </Box>

        {/* web view */}
        <Box display={{ xs: "none", sm: "block" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h3">
            Privacy Policy
          </Typography>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              YOUR PERSONAL DATA AND HOW WE USE IT
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we collect, use and store your personal data to ensure we can
              provide various services to you and offer you a personal
              experience online and in our stores. this may occur on our Site or
              via other means. we will keep your personal data confidential at
              all times and only collect, use and store in accordance with this
              Privacy Policy.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              COLLECTION OF YOUR PERSONAL DATA
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we collect the different levels of your personal data, both on our
              site and at our physical stores:
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  you will be browsing our site as a guest if you are not a
                  registered user. we will not collect or use any personal data
                  from you, but you may not be able to use all the services and
                  features that we offer to registered users.
                </li>
                <li>
                  you will need to input certain of your personal data when
                  registering with us on our site (such as your name and contact
                  details), in order to receive various features and services
                  that we offer to our registered users, such as email updates,
                  responses to your queries, and priority for our limited
                  edition items.
                </li>
                <li>
                  you can only place an order with us as a registered user on
                  our site. in both cases, we will only collect information
                  necessary to fullfill the relevant order (such as your name,
                  contact details and payment/credit card information).
                </li>
                <li>
                  you may wish to verify or update any of your personal data
                  that we hold. you may do so by{" "}
                  <StyledLink to="/contact-us">
                    contacting our Customer Service
                  </StyledLink>
                  , or amending your customer profile in your registered
                  account.
                </li>
              </ul>
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              USE OF YOUR PERSONAL DATA
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we use the personal data you provide to us for the following
              purposes:
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  to provide our services to you. This includes responding any
                  of your enquiries, providing you with recommendations on
                  products on our Site, maintaining your shopping cart on our
                  site, assessing your applications with us for any of our
                  services, and fulfilling orders you may make with us
                  (including the security checks of your payments and
                  verification).
                </li>
                <li>
                  to provide you with marketing communications, as we may
                  occasionally update you our latest products, news via e-mail,
                  post and telephone.
                </li>
              </ul>
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              COOKIES
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              please note that we may automatically collect data from you
              relating to your online session to provide our services to you.
              our site may use cookies, which is a small information file that
              is sent to your computer or other device when you visit our site
              and it will recoganize your device on future visits, for example,
              remembering your items in shopping cart. If you do not want
              cookies to be stored on your computer, you can change the settings
              on your browser. The “help” section on your browser will provide
              details on how to manage the cookie settings.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              MARKETING COMMUNICATIONS
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              you agree that we may use your personal data to offer you
              marketing communications from time to time. we may provide you our
              goods and services, and the goods and services of our third party
              providers. this may be presented in various forms, including
              newsletters, advertisements and special promotional events, and
              delivered by various methods based on the personal information you
              provide to us, such as by email, phone notifications or postal
              mail.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              you have the options to opt-out of receiving marketing
              communications from us. if you do not wish to continue to receive
              it, you may click on the “unsubscribe” link in any email
              communications which we might send you, or{" "}
              <StyledLink to="/contact-us">contact our Customer Service</StyledLink>{" "}
              so that we could amend your personal data for you.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              PROTECTING YOUR SECURITY
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we do our best to protect the personal data that you provide to
              us. please be aware that despite our efforts, no data security
              measures can guarantee 100% security at all times.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              when you make online purchase, we protect the personal data that
              you provide to us. we will encrypt any personal data you transmit
              to us via our site using Secure Socket Layer (SSL) – an industry
              standard for encryption over the Internet.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we may use your personal information in order to conduct
              appropriate anti-fraud checks. this may be disclosed to a credit
              reference agency, which may keep a record of the information. this
              is only for confirming your identity, a credit check is not
              performed and your credit rating is unaffected.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Nothing in this Privacy Policy shall limit any of your rights to
              the extent such rights cannot be limited by law.
            </Typography>{" "}
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              DISCLOSURES OF YOUR INFORMATION
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              your personal data will be kept confidential with us, except the
              following parties that we may disclose your personal data to them:
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  any financial institutions or credit card issuing companies,
                  or collection agencies where necessary to establish and
                  support the payment being made by you
                </li>
                <li>
                  any parties where such disclosure is mandated by statutory or
                  contractual obligations or as required by law
                </li>
              </ul>
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              this Privacy Policy is not applicable to any websites maintained
              by third parties. please read such third party’s privacy policy
              before providing your personal data to such third parties (whether
              via our Site or not). we are not responsible and liable for any
              use or breach of your personal data by such third parties.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              HOW TO CHECK AND UPDATE YOUR DETAILS
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you wish to verify the personal data you provide to us, you may
              do so by logging in to your account to update your personal data,
              or contacting our Customer Service by{" "}
              <StyledLink to="/contact-us">Contact Us</StyledLink>. when you contact
              us, our security procedures mean that we may request proof of
              identity before we reveal information. this proof will take the
              form of your email address and password submitted upon
              registration. You must keep this information safe, as you will be
              responsible for any action, which we will take in response to a
              request from someone using your email and password. we strongly
              recommend that you do not use the browser’s password memory
              function, as that would permit others using your terminal to
              access your personal information.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              CONTACTING US
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              If you have any questions or feedback about our Privacy Policy,
              please do not hesitate to Contact Us.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default PrivacyPolicy;

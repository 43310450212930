import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Box, Container, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: #000;
`;

function ReturnPolicy() {
  return (
    <Box>
      <Navbar />
      <Container fixed maxWidth="md" style={{ margin: "5rem auto" }}>
        {/* mobile view */}
        <Box display={{ xs: "block", sm: "none" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
            Exchange & Return Policy
          </Typography>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you discover a fault with the item(s) you have received, please
              <StyledLink to="/contact-us">contact us</StyledLink> straight away and
              our customer service representatives will try to resolve the issue
              for you as soon as possible.
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you are not satisfied with your items and ask for a refund,
              unfortunately we cannot readily accept all returns for any
              products. if the style, colour and size does not meet your
              standard, we apologise that it will not be acceptable reasons.
              please take this into consideration before you check out. if you
              have any questions about our product, please go to{" "}
              <StyledLink to="/contact-us">contact us</StyledLink>, our Customer
              Service team is delighted to help.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              should you discover any faulty item(s) that you have received and
              wish to return them, please refer to the guideline below and
              <StyledLink to="/contact-us">contact us</StyledLink> explaining the
              reason for your return request, within 7 days from receiving your
              order. NO returns will be accepted after this date. initial
              shipping cost is non-refundable.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              once we authorise your return request, we will provide you with a
              case number for returning the order.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              exchanges/returns will be processed upon receipt of the regarding
              item(s). acknowledgement of receipt will be sent electronically
              following the evaluation process, stating the outcome and whether
              the item is eligible for exchange or refund.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              your refund will be credited back to your account used to make the
              purchase. refunds may take up to 14 days, depends on the card
              issuer’s or payment gateways’ refund processing time.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              Exchange/returns Conditions{" "}
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you are eligible to the above conditions, please refer to the
              following criteria as a guideline for our exchange/returns policy:
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              general{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  include our return item(s) in its original condition with your
                  assigned case number.
                </li>
                <li>items must not be damaged.</li>
                <li>
                  items must be in new/original condition with all labels and
                  packaging attached.
                </li>
                <li>
                  orders must be returned within 14 days from receiving your
                  package.
                </li>
                <li>all items are eligible for only a one-time exchange.</li>
                <li>
                  exceptions and other conditions will be noted within the item
                  description.
                </li>
                <li>evaluation process may take up to 14 days from receipt.</li>
                <li>sales/discounted item(s) are not eligible for return.</li>
                <li>
                  all returns will be refunded in through your original form of
                  payment.
                </li>
                <li>
                  upon dispute, we reserves the right to make the final decision
                  on all return cases.
                </li>
              </ul>
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              excluded return items{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>accessories </li>
                <li>bags</li>
                <li>belts</li>
                <li>books and prints (stickers, stationery)</li>
                <li>socks</li>
              </ul>
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we have made every effort to display as accurately as possible the
              colours of our products that appear on blackhill. however, as
              computer monitors vary, we cannot guarantee that your monitor's
              display of any colour will be completely accurate.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              please note that customers are responsible for all the fees
              involved for returning items. therefore, it is recommended that
              the customer take necessary precautions by using registered and
              traceable services when returning the package.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we will provide you with a postal address for returning the
              package via email.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              incorrect item, missing item or faulty item
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you believe there is a fault with an item you have received,
              please <StyledLink to="/contact-us">contact us</StyledLink> as soon as
              you discover the issue to start the exchange/returns procedure and
              we will do our best to resolve the problem. replacement will be
              sent where the item is still available. otherwise, you will get a
              refund after returning the product. under these circumstances, the
              shipping cost of the replacement where items are missing, or the
              return shipment where an incorrect or faulty item has been
              received will be covered by us.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              all sales and discounted orders are final and can not be
              cancelled. we do not accept returns or exchanges for sales,
              discounted and promotion items.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              cancellation{" "}
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              no cancellation, exchange or refund could be made once the orders
              are completed and confirmed.
            </Typography>
          </Box>

          <Box m={0} p={2} style={{ paddingBottom: 0 }}>
            <Typography style={{ fontFamily: "El Messiri" }} variant="h6">
              enquiries{" "}
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              for general enquiries/online enquiries, please go to{" "}
              <StyledLink to="/contact-us">contact us</StyledLink>, our Customer
              Service is on hand to support.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Business Hour :{" "}
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Monday-Friday 10:00am - 6pm (Hong Kong GMT+8){" "}
            </Typography>
          </Box>
        </Box>

        {/* web view */}
        <Box display={{ xs: "none", sm: "block" }}>
          <Typography style={{ fontFamily: "El Messiri" }} variant="h3">
            Exchange & Return Policy
          </Typography>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you discover a fault with the item(s) you have received, please
              <StyledLink to="/contact-us">contact us</StyledLink> straight away and
              our customer service representatives will try to resolve the issue
              for you as soon as possible.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you are not satisfied with your items and ask for a refund,
              unfortunately we cannot readily accept all returns for any
              products. if the style, colour and size does not meet your
              standard, we apologise that it will not be acceptable reasons.
              please take this into consideration before you check out. if you
              have any questions about our product, please go to{" "}
              <StyledLink to="/contact-us">contact us</StyledLink>, our Customer
              Service team is delighted to help.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              should you discover any faulty item(s) that you have received and
              wish to return them, please refer to the guideline below and
              <StyledLink to="/contact-us">contact us</StyledLink> explaining the
              reason for your return request, within 7 days from receiving your
              order. NO returns will be accepted after this date. initial
              shipping cost is non-refundable.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              once we authorise your return request, we will provide you with a
              case number for returning the order.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              exchanges/returns will be processed upon receipt of the regarding
              item(s). acknowledgement of receipt will be sent electronically
              following the evaluation process, stating the outcome and whether
              the item is eligible for exchange or refund.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              your refund will be credited back to your account used to make the
              purchase. refunds may take up to 14 days, depends on the card
              issuer’s or payment gateways’ refund processing time.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              Exchange/returns Conditions{" "}
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you are eligible to the above conditions, please refer to the
              following criteria as a guideline for our exchange/returns policy:
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              general{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>
                  include our return item(s) in its original condition with your
                  assigned case number.
                </li>
                <li>items must not be damaged.</li>
                <li>
                  items must be in new/original condition with all labels and
                  packaging attached.
                </li>
                <li>
                  orders must be returned within 14 days from receiving your
                  package.
                </li>
                <li>all items are eligible for only a one-time exchange.</li>
                <li>
                  exceptions and other conditions will be noted within the item
                  description.
                </li>
                <li>evaluation process may take up to 14 days from receipt.</li>
                <li>sales/discounted item(s) are not eligible for return.</li>
                <li>
                  all returns will be refunded in through your original form of
                  payment.
                </li>
                <li>
                  upon dispute, we reserves the right to make the final decision
                  on all return cases.
                </li>
              </ul>
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              excluded return items{" "}
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="left"
            >
              <ul>
                <li>accessories </li>
                <li>bags</li>
                <li>belts</li>
                <li>books and prints (stickers, stationery)</li>
                <li>socks</li>
              </ul>
            </Typography>
            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we have made every effort to display as accurately as possible the
              colours of our products that appear on blackhill. however, as
              computer monitors vary, we cannot guarantee that your monitor's
              display of any colour will be completely accurate.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              please note that customers are responsible for all the fees
              involved for returning items. therefore, it is recommended that
              the customer take necessary precautions by using registered and
              traceable services when returning the package.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              we will provide you with a postal address for returning the
              package via email.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              incorrect item, missing item or faulty item
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              if you believe there is a fault with an item you have received,
              please <StyledLink to="/contact-us">contact us</StyledLink> as soon as
              you discover the issue to start the exchange/returns procedure and
              we will do our best to resolve the problem. replacement will be
              sent where the item is still available. otherwise, you will get a
              refund after returning the product. under these circumstances, the
              shipping cost of the replacement where items are missing, or the
              return shipment where an incorrect or faulty item has been
              received will be covered by us.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              all sales and discounted orders are final and can not be
              cancelled. we do not accept returns or exchanges for sales,
              discounted and promotion items.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              cancellation{" "}
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              no cancellation, exchange or refund could be made once the orders
              are completed and confirmed.
            </Typography>
          </Box>

          <Box m={5} p={2} style={{ paddingBottom: 0 }}>
            <Typography
              style={{ fontFamily: "El Messiri", marginBottom: "1rem" }}
              variant="h5"
            >
              enquiries{" "}
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              for general enquiries/online enquiries, please go to{" "}
              <StyledLink to="/contact-us">contact us</StyledLink>, our Customer
              Service is on hand to support.
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Business Hour :{" "}
            </Typography>

            <Typography
              style={{ fontFamily: "El Messiri" }}
              variant="body1"
              align="justify"
            >
              Monday-Friday 10:00am - 6pm (Hong Kong GMT+8){" "}
            </Typography>
          </Box>
        </Box>
      </Container>

      <Footer />
    </Box>
  );
}

export default ReturnPolicy;

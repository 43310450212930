import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { Box, Container, Typography } from "@material-ui/core";
const StyledImg = styled(motion.img)`
  /* display: "block"; */
  backdrop-filter: blur(1px);
  width: 90vw;
  max-width: 1200px;
  /* max-height: 1200px; */
  object-fit: contain;
  filter: drop-shadow(8px 12px 2px #d3d3d3);
  -webkit-filter: drop-shadow(8px 12px 2px #d3d3d3);
  /* z-index: 1000; */
`;
function ProductImageBox({ imageUrl }) {
  return (
    <motion.div>
      <Box display={{ xs: "none", sm: "block" }} >
        <StyledImg key={1} src={imageUrl} alt="" style={{ height: '70vh' }}/>
      </Box>
      <Box display={{ xs: "block", sm: "none" }}>
        <StyledImg key={1} src={imageUrl} alt="" />
      </Box>
    </motion.div>
  );
}

export default ProductImageBox;

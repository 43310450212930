import React from "react";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getProductDetailLink } from "../utils/product";

const gridBoxTitleVariants = {
  initial: { opacity: 0 },
  hover: { opacity: 1, transition: { duration: 2 } },
};

const gridBoxImageVariants = {
  initial: { scale: 0.8, transition: { duration: 1 } },
  hover: { scale: 0.85, transition: { duration: 1 } },
};

const GridBoxWrapper = styled(motion.div)`
  background-color: #f5f5f5;
  position: relative;
  width: 30%;
  min-width: 300;
  margin: 0.6rem;
`;

const GridBoxTitle = styled(motion.div)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10;
  font-weight: bolder;
`;

const GridBoxImage = styled(motion.img)`
  width: 100%;
  filter: drop-shadow(8px 12px 2px #ddd);
`;

function WebProductGridBox({ product }) {
  const history = useHistory();

  return (
    <GridBoxWrapper
      whileHover="hover"
      onClick={() => history.push(getProductDetailLink(product.node.id))}
    >
      <GridBoxTitle initial="initial" variants={gridBoxTitleVariants}>
        {product.node.title}
      </GridBoxTitle>

      <GridBoxImage
        src={product.node.images.edges[0].node.src}
        alt="product"
        initial="initial"
        variants={gridBoxImageVariants}
      />
    </GridBoxWrapper>
  );
}

export default WebProductGridBox;

import React from "react";
import { LinearProgress } from "@material-ui/core";

function CheckingOut() {
  return (
    <div style={{ position: "relative", width: "100vw", height: "100vh" }}>
      <div
        style={{
          fontFamily: "Manrope",
          fontSize: '1rem',
          fontWeight: 'bolder',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <p>PREPARING CHECKOUT</p>
        <LinearProgress/>
      </div>
    </div>
  );
}

export default CheckingOut;

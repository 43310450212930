import React from "react";
// product descriptions
import WildpiLongSleevesDescription from "./productDescriptions/WildpiLongSleevesDescription";
import TryMyBestILoveRunningTeeDescription from "./productDescriptions/TryMyBestILoveRunningTeeDescription";
import TryMyBestHongKong1841TeeDescription from "./productDescriptions/TryMyBestHongKong1841TeeDescription";
import HikeExitProtectOurMountainsHikingTeeDescription from "./productDescriptions/HikeExitProtectOurMountainsHikingTeeDescription";
import { Box, Typography } from "@material-ui/core";

const Descriptions = (product) => {
  const title = product.product.title;
  const description = product.product.description;
  return (
    <>
      {/* wildpi long sleeves description */}
      {title.includes("Long Sleeves") && <WildpiLongSleevesDescription />}

      {/* try my best - I Love Running description */}
      {title === "Try My Best I Love Running Tee" && (
        <TryMyBestILoveRunningTeeDescription />
      )}

      {/* try my best - 1841 description */}
      {title === "Try My Best Hong Kong 1841 Tee" && (
        <TryMyBestHongKong1841TeeDescription />
      )}

      {/* pre-order / Hike Exit description */}
      {title === "Hike Exit Protect Our Mountains Hiking Tee" && (
        <HikeExitProtectOurMountainsHikingTeeDescription />
      )}

      {/* description - others */}
      {title.split(" ").reverse().pop() !== "Try" ||
      title.split(" ").reverse().pop() !== "Hike" ? (
        <Box m={2} style={{ marginBottom: "3rem" }}>
          <Typography variant="body1">{description}</Typography>
        </Box>
      ) : null}
    </>
  );
};

export default Descriptions;

import React from "react";
import { Box } from "@material-ui/core";
// size chart
import LongSleevesSizeChart from "./sizeCharts/LongSleevesSizeChart";
import PantsSizeChart from "./sizeCharts/PantsSizeChart";
import ShortsSizeChart from "./sizeCharts/ShortsSizeChart";
import DressSizeChart from "./sizeCharts/DressSizeChart";
import CapSizeChart from "./sizeCharts/CapSizeChart";
import TryMyBestSizeChart from "./sizeCharts/TryMyBestSizeChart";
import HikeExitSizeChart from "./sizeCharts/HikeExitSizeChart";
import LowPocketTeeSizeChart from "./sizeCharts/LowPocketTeeSizeChart";
import GirlLifestyleBigPocketsShortsSizeChart from "./sizeCharts/GirlLifestyleBigPocketsShortsSizeChart";
import TryMyBestRunningShortsBlueSizeChart from "./sizeCharts/TryMyBestRunningShortsBlueSizeChart";
import TryMyBestFunctionalShortsSizeChart from "./sizeCharts/TryMyBestFunctionalShortsSizeChart";
import TryMyBestDoublePocketsShortsSizeChart from "./sizeCharts/TryMyBestDoublePocketsShortsSizeChart";
import MeshHikingPantsSizeChart from "./sizeCharts/MeshHikingPantsSizeChart";
import WildpiBlackhillMeshHikingPantsSizeChart from "./sizeCharts/WildpiBlackhillMeshHikingPantsSizeChart";
import TryMyBestRunningTeeSizeChart from "./sizeCharts/TryMyBestRunningTeeSizeChart";
import ColabCapSizeChart from "./sizeCharts/ColabCapSizeChart";
import RunningShortsSizeChart from "./sizeCharts/RunningShortsSizeChart";
import Mesh2HikingPantsSizeChart from "./sizeCharts/Mesh2HikingPantsSizeChart";
import AlmightyJacketSizeChart from "./sizeCharts/AlmightyJacketSIzeChart";
import ILoveTrailingRunningSizeChart from "./sizeCharts/ILoveTrailRunningTeeSizeChart";

const SizeCharts = (product) => {
  //   console.log("size chart", product.title);
  const title = product.title;
  return (
    <Box m={-3}>
      {title === "Lifestyle 3D Wide Pants" ? <PantsSizeChart /> : null}

      {title === "Lifestyle Big Pockets Shorts" ? <ShortsSizeChart /> : null}

      {title === "Blackhill Ripstop Dress" ? <DressSizeChart /> : null}

      {title === "Blackhill Ripstop Cap" ? <CapSizeChart /> : null}

      {title === "Blackhill Bonnie Hat" ? <CapSizeChart /> : null}

      {title.toString().includes("Wildpi Long Sleeves") ? (
        <LongSleevesSizeChart />
      ) : null}

      {title === "Try My Best I Love Running Tee" ? (
        <TryMyBestSizeChart />
      ) : null}

      {title === "Try My Best Hong Kong 1841 Tee" ? (
        <HikeExitSizeChart />
      ) : null}

      {title.toString().includes("Try My Best Running Shorts") ? (
        <TryMyBestRunningShortsBlueSizeChart />
      ) : null}

      {title === "Try My Best Functional Hiking Shorts" ? (
        <TryMyBestFunctionalShortsSizeChart />
      ) : null}

      {title === "Try My Best Double Pockets Cargo Shorts" ? (
        <TryMyBestDoublePocketsShortsSizeChart />
      ) : null}

      {title === "Hike Exit Protect Our Mountains Hiking Tee" ? (
        <HikeExitSizeChart />
      ) : null}

      {title === "blackhill Big Logo (3M) Tee" ? (
        <LowPocketTeeSizeChart />
      ) : null}

      {title === "Low Pockets Tee" ? <LowPocketTeeSizeChart /> : null}

      {title === "Lifestyle Big Pockets Shorts (Girl)" ? (
        <GirlLifestyleBigPocketsShortsSizeChart />
      ) : null}

      {title === "Mesh Hiking Pants" ? <MeshHikingPantsSizeChart /> : null}

      {title === "Wildpi x blackhill Mesh Hiking Pants" ? (
        <WildpiBlackhillMeshHikingPantsSizeChart />
      ) : null}

      {title === "Try My Best Running Tee I" ? (
        <TryMyBestRunningTeeSizeChart />
      ) : null}

      {title === "Try My Best Running Tee II" ? (
        <TryMyBestRunningTeeSizeChart />
      ) : null}

      {title === "Hike Exit x blackhill Nylon Cap" ? (
        <ColabCapSizeChart />
      ) : null}

      {title === "Hakdim x blackhill Flip Cap" ? <ColabCapSizeChart /> : null}

      {title.toString().includes("blackhill Running Shorts") ? (
        <RunningShortsSizeChart />
      ) : null}

      {title.toString().includes("Mesh2 Hiking Pants") ? (
        <Mesh2HikingPantsSizeChart />
      ) : null}

      {title.toString().includes("Almighty Jacket") ? (
        <AlmightyJacketSizeChart />
      ) : null}

      {title === "Hong Kong 1841 Tee (New Version)" ? (
        <ILoveTrailingRunningSizeChart />
      ) : null}

      {title === "I Love Trail Running Tee" ? (
        <ILoveTrailingRunningSizeChart />
      ) : null}
    </Box>
  );
};

export default SizeCharts;
